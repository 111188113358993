import React from "react";
import LanguageString from "../LanguageString";

type ErrorObject = {
  messageId: string;
  errorCode?: string | number;
};

type LogoutButtonProps = {
  action: () => void;
  onError: (err: string | ErrorObject) => void;
};

export default function LogoutButton({ action, onError }: LogoutButtonProps) {
  async function logout() {
    try {
      // const response = await fetch("https://launcher.mtdream.net/api/account/logout", {
      const response = await fetch("https://launcher.dawntera.com/api/account/logout", {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok && response.status !== 401) {
        onError({ messageId: "UI_TEXT_UNEXPECTED_SERVER_RESPONSE", errorCode: response.status });
        return;
      }

      window.localStorage.removeItem("username");
      action();
    } catch (_) {
      onError("UI_TEXT_CONNECTION_ERROR");
    }
  }

  return (
    <li onClick={logout}>
      <LanguageString stringId="UI_TEXT_LOG_OUT" />
    </li>
  );
}
