import React from "react";
import "./SocialMediaButton.css";
import { faDiscord, faFacebookF, faInstagram, faTwitch, faTwitter, faVk, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faHome } from "@fortawesome/free-solid-svg-icons";
// import { faPumpkin } from "@fortawesome/pro-solid-svg-icons";
import faPumpkin from "../../customIcons";

type IconsType = {
  [key: string]: IconDefinition;
};

type TitleType = {
  [key: string]: string;
};

type SocialMediaButtonProps = {
  type: string;
  url: string;
};

const icons: IconsType = {
  discord: faDiscord,
  facebook: faFacebookF,
  twitter: faTwitter,
  instagram: faInstagram,
  twitch: faTwitch,
  youtube: faYoutube,
  homepage: faHome,
  moongourd: faPumpkin,
  vk: faVk,
};

const titles: TitleType = {
  discord: "Discord",
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  twitch: "Twitch",
  youtube: "Youtube",
  homepage: "Homepage",
  moongourd: "Moongourd",
};

export default function SocialMediaButton({ type, url }: SocialMediaButtonProps) {
  function onClick() {
    window.ipcRenderer.send("openExternal", url);
  }

  return (
    <li className="Social-Media-Button" onClick={onClick} title={titles[type]}>
      <FontAwesomeIcon icon={icons[type]} fixedWidth />
    </li>
  );
}
