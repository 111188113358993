import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faXmark, faMinus, faUser, IconDefinition, faToolbox } from "@fortawesome/free-solid-svg-icons";
import "./HeaderButton.css";
import LanguageString from "../LanguageString";
import { LoginContext } from "../../Contexts";

type HeaderButtonProps = {
  type: "account" | "proxy" | "settings" | "minimize" | "close";
  showModal?: () => void;
  children?: ReactNode;
};

export default function HeaderButton({ children, type, showModal }: HeaderButtonProps) {
  const [dropdownShowing, setDropdownShowing] = React.useState<boolean>(false);
  const username = React.useContext(LoginContext);
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function ClickHandler(event: MouseEvent) {
      if (!menuRef.current?.contains(event.target as Node)) {
        setDropdownShowing(false);
      }
    }

    document.addEventListener("click", ClickHandler);
    return () => document.removeEventListener("click", ClickHandler);
  });

  let className = "Header-Button-Container";
  let icon: IconDefinition | undefined, onClick, hovertext;

  // eslint-disable-next-line
  switch (type) {
    case "account": {
      icon = faUser;
      onClick = () => setDropdownShowing(!dropdownShowing);
      hovertext = <LanguageString stringId="UI_TEXT_TOOLTIP_ACCOUNT" />;
      break;
    }
    case "proxy": {
      icon = faToolbox;
      onClick = () => window.ipcRenderer.send("showProxy");
      hovertext = <LanguageString stringId="UI_TEXT_TOOLTIP_PROXY" />;
      break;
    }
    case "settings": {
      icon = faGear;
      onClick = () => {
        if (showModal) showModal();
      };
      hovertext = <LanguageString stringId="UI_TEXT_TOOLTIP_SETTINGS" />;
      break;
    }
    case "minimize": {
      icon = faMinus;
      onClick = () => window.ipcRenderer.send("window-minimize");
      hovertext = <LanguageString stringId="UI_TEXT_TOOLTIP_MINIMIZE" />;
      break;
    }
    case "close": {
      icon = faXmark;
      className += " close-button";
      onClick = () => window.ipcRenderer.send("window-close");
      hovertext = <LanguageString stringId="UI_TEXT_TOOLTIP_CLOSE" />;
      break;
    }
  }

  return (
    <li className={className}>
      <div className="Header-Button tooltip" style={username ? { padding: "0 12px" } : undefined} onClick={onClick} ref={menuRef}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {type === "account" && username && <span style={{ marginLeft: "5px", lineHeight: 1 }}>{username}</span>}
        <span className="tooltiptext">{hovertext}</span>
      </div>
      {dropdownShowing && <ul className="Dropdown-Menu">{children}</ul>}
    </li>
  );
}
