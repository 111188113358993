import React, { ReactElement } from "react";
import LanguageString from "./LanguageString";
import "./PatchProgress.css";

type PatchProgressProps = {
  patchStage: number;
  setPatchStage: React.Dispatch<React.SetStateAction<number>>;
};

export default function PatchProgress({ patchStage, setPatchStage }: PatchProgressProps) {
  const [progressString, setProgressString] = React.useState<ReactElement | null>(null);
  const [progress, setProgress] = React.useState(100);

  React.useEffect(() => {
    window.ipcRenderer.on("patchProgress", (event, status, stringId, percentage, downloadSize, totalSize, downloadSpeed, timeRemaining, errorMessage) => {
      setPatchStage(status);
      setProgressString(<LanguageString stringId={stringId} percentage={percentage} downloadSize={downloadSize} totalSize={totalSize} downloadSpeed={downloadSpeed} timeRemaining={timeRemaining} errorMessage={errorMessage} />);
      setProgress(percentage);
    });
    // eslint-disable-next-line
  }, []);

  const style = {
    width: progress + "%",
  };

  return (
    <div className="Patch-Progress-Container" style={{ visibility: patchStage === 0 ? "hidden" : "visible" }}>
      <p className="Patch-Progress-Status-Text">{progressString}</p>
      <div className="Patch-Progress-Bar">
        <div className="Patch-Progress-Bar-Progress" style={style}></div>
      </div>
    </div>
  );
}
