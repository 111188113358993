import { IconDefinition, IconPrefix, IconName } from "@fortawesome/fontawesome-svg-core";

const faPumpkin: IconDefinition = {
  prefix: "fac" as IconPrefix,
  iconName: "pumpkin" as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M234.6 103.9C251.5 98.8 269.4 96 288 96c22.8 0 44.7 4.2 64.8 11.7c-.6-2-.8-4.1-.8-6.3V35.4c0-6.9-4.1-13.2-10.5-15.9L299.3 1.4c-2.2-.9-4.5-1.4-6.8-1.4h-1.2c-6.9 0-13.1 4.1-15.8 10.4l-40.9 93.5zm161.6 27.3c12.1 8.8 23.1 19.1 32.7 30.5c5.6 6.7-2.9 14.6-10.4 10.1C380.3 149 335.7 136 288 136s-92.3 13-130.5 35.7c-7.5 4.5-16-3.4-10.4-10.1c9.6-11.4 20.6-21.7 32.7-30.5c-.6-.1-1.2-.2-1.8-.3c-8.5-1.8-17.2-2.8-26-2.8C68.1 128 0 214 0 320S68.1 512 152 512c19.3 0 37.8-4.5 54.7-12.8c8.3-4.1 18.2-4.1 26.5 0c17 8.3 35.4 12.8 54.7 12.8s37.8-4.5 54.7-12.8c8.3-4.1 18.2-4.1 26.5 0c17 8.3 35.4 12.8 54.7 12.8c83.9 0 152-86 152-192s-68.1-192-152-192c-8.9 0-17.6 1-26 2.8c-.6 .1-1.2 .2-1.8 .3z", // SVG path data
  ],
};

// add
const faCircleInfo: IconDefinition = {
  prefix: "fac" as IconPrefix,
  iconName: "None" as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z", // SVG path data
  ],
};

const faTriangleExclamation: IconDefinition = {
  prefix: "fac" as IconPrefix,
  iconName: "Minor" as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z", // SVG path data
  ],
};

const faCircleExclamation: IconDefinition = {
  prefix: "fac" as IconPrefix,
  iconName: "Major" as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z", // SVG path data
  ],
};

const faCircleXmark: IconDefinition = {
  prefix: "fac" as IconPrefix,
  iconName: "Critical" as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z", // SVG path data
  ],
};

const faWrench: IconDefinition = {
  prefix: "fac" as IconPrefix,
  iconName: "Maintenance" as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7L336 192c-8.8 0-16-7.2-16-16l0-57.4c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z", // SVG path data
  ],
};

export default faPumpkin;
