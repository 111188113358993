import React from "react";
import { LanguageContext } from "../Contexts";

type LanguageSubStrings = {
  [key: string]: string;
};

type LanguageStrings = {
  [key: string]: string | LanguageSubStrings;
};

type LanguageStringsWrapper = {
  [key in LanguageType]: LanguageStrings;
};

const strings: LanguageStringsWrapper = {
  ko: {
    UI_TEXT_UPDATE_CHECK_FOR_UPDATES: "업데이트 확인 중...",
    UI_TEXT_UPDATE_DOWNLOADING_UPDATE: "업데이트 다운로드 중 {percentage}%...",
    UI_TEXT_UPDATE_VERIFYING_INTEGRITY: "무결성 확인 중...",
    UI_TEXT_UPDATE_PREPARING_TO_INSTALL: "업데이트 설치 준비 중...",
    UI_TEXT_UPDATE_INSTALLING_UPDATE: "업데이트 설치 중...",
    UI_TEXT_UPDATE_FAILED: "업데이트 실패 오류: {error}",
    UI_TEXT_WELCOME_USERNAME: "Welcome, {username}",
    UI_TEXT_LOG_OUT: "로그아웃",
    UI_TEXT_PATCH_PROGRESS_COMPLETED: "완료",
    UI_TEXT_PATCH_PROGRESS_CHECK_FOR_UPDATES: "업데이트 확인 중...",
    UI_TEXT_PATCH_PROGRESS_RETRIEVING_INFO: "업데이트 정보 검색 중...",
    UI_TEXT_PATCH_PROGRESS_PREALLOCATING: "저장 공간 할당 중...",
    UI_TEXT_PATCH_PROGRESS_CHECKING_EXISTING: "기존 파일을 확인하는 중입니다. 잠시만 기다려 주세요. 시간이 좀 걸릴 수 있습니다...",
    UI_TEXT_PATCH_PROGRESS_DOWNLOADING_FILES: "다운로드 파일 {percentage}%... ({downloadSize}/{totalSize} - {downloadSpeed} - 남은 시간: {timeRemaining})",
    UI_TEXT_PATCH_PROGRESS_EXTRACTING_FILES: "파일 추출 중 {percentage}%...",
    UI_TEXT_PATCH_PROGRESS_CLEANING_UP: "청소 중...",
    UI_TEXT_PATCH_PROGRESS_DOWNLOAD_PAUSED: "다운로드 정지 {percentage}%. ({downloadSize}/{totalSize})",
    UI_TEXT_PATCH_PROGRESS_FAILED: "업데이트 실패, 오류가 발생했습니다. 런처를 다시 시작하고 시도해 주세요.",
    UI_TEXT_PATCH_PROGRESS_REMOVE_LEGACY_INSTALL: "기존 파일을 제거하는 중입니다. 잠시 기다려 주십시오...",
    UI_TEXT_LAUNCH_BUTTON_LOG_IN: "로그인",
    UI_TEXT_LAUNCH_BUTTON_PLAY: "게임 시작",
    UI_TEXT_LAUNCH_BUTTON_LAUNCHING: "시작 중...",
    UI_TEXT_LAUNCH_BUTTON_GAME_RUNNING: "실행 중",
    UI_TEXT_PATCH_PROGRESS_BUTTON_PAUSE: "정지",
    UI_TEXT_PATCH_PROGRESS_BUTTON_RESUME: "재시작",
    UI_TEXT_LOGIN_MODAL_REMEMBER_ME: "로그인 상태 유지",
    UI_TEXT_LOGIN_MODAL_PASSWORD_RESET: "비밀번호 찾기",
    UI_TEXT_LOGIN_MODAL_CREATE_NEW_ACCOUNT: "회원가입",
    UI_TEXT_LOGIN_FAIL_INVALID_USERNAME_PASSWORD: "잘못된 사용자 아이디 또는 비밀번호.",
    UI_TEXT_LOGIN_FAIL_ACCOUNT_NOT_ACTIVATED: "계정이 비활성화 상태입니다, 활성화 링크는 이메일을 확인하십시오.",
    UI_TEXT_LOGIN_FAIL_UNEXPECTED_ERROR: "예기치 않은 오류입니다. 다시 시도하십시오.",
    UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT: "서버에 연결할 수 없습니다. 다시 시도하십시오.",
    UI_TEXT_CLIENT_MANAGE_DROPDOWN_CHECK_FOR_UPDATES: "게임 업데이트 확인",
    UI_TEXT_CLIENT_MANAGE_DROPDOWN_REPAIR_GAME: "게임 복구",
    UI_TEXT_CLIENT_MANAGE_DROPDOWN_OPEN_GAME_FOLDER: "게임 폴더 열기",
    UI_TEXT_LOGIN_MODAL_LOG_IN: "로그인",
    UI_TEXT_REGISTER_MODAL_LOG_IN: "회원가입",
    UI_TEXT_NOTICE_MODAL_TITLE: "공지사항",
    UI_TEXT_CLIENT_REPAIR_MODAL_TITLE: "클라이언트 복구",
    UI_TEXT_CLIENT_REPAIR_MODAL_MESSAGE: "복구를 진행할 수 있습니다. 시간이 오래 걸릴 수 있습니다. 계속하시겠습니까?",
    UI_TEXT_CLIENT_REPAIR_MODAL_BUTTON_START: "복구 시작",
    UI_TEXT_GENERIC_MODAL_BUTTON_OK: "확인",
    UI_TEXT_GENERIC_MODAL_BUTTON_CANCEL: "취소",
    UI_TEXT_TOS_MODAL_TITLE: "서비스 약관 및 개인정보 취급방침",
    UI_TEXT_TOS_MODAL_DESCRIPTION: "계속하기 전에 아래 링크의 서비스 약관 및 개인 정보 보호 정책을 읽어보십시오. 동의를 클릭하면 해당 조항을 이해하고 동의했음을 나타냅니다.",
    UI_TEXT_TOS_MODAL_CLICK_BELOW_MESSAGE: "서비스 약관 및 개인 정보 보호 정책을 보려면 아래를 클릭하십시오.",
    UI_TEXT_TOS_MODAL_TOS_CHECKBOX: "서비스 조건",
    UI_TEXT_TOS_MODAL_PRIVACY_CHECKBOX: "개인정보 보호정책",
    UI_TEXT_GENERIC_MODAL_BUTTON_ACCEPT: "동의",
    UI_TEXT_GENERIC_MODAL_BUTTON_DECLINE: "거절",
    UI_TEXT_CONNECTION_ERROR: "서버에 연결할 수 없습니다. 다시 시도하십시오.",
    UI_TEXT_UNEXPECTED_SERVER_RESPONSE: "서버스 응답 상태 ({errorCode})",
    UI_TEXT_SETTINGS_MODAL_TITLE: "설정",
    UI_TEXT_SETTINGS_LANG_SELECT_TITLE: "언어",
    UI_TEXT_SETTINGS_LIMIT_DOWNLOAD_TITLE: "다운로드 속도 제한",
    UI_TEXT_SETTINGS_LAUNCHERV_TITLE: "런처 버전",
    UI_TEXT_SETTINGS_LAUNCHERV_VERSION: "버전 {version}",
    UI_TEXT_SETTINGS_ABOUT_TITLE: "정보",
    UI_TEXT_SETTINGS_LAUNCHERV_UPDATE_BUTTON: "런처 업데이트",
    UI_TEXT_SETTINGS_LAUNCHERV_REPAIR_BUTTON: "런처 복구",
    UI_TEXT_SETTINGS_ABOUT_TOS: "서비스 조건",
    UI_TEXT_SETTINGS_ABOUT_PRIVACY: "개인정보 보호정책",
    UI_TEXT_SETTINGS_ABOUT_SERVICE_STATUS: "서비스 상태",
    UI_TEXT_TOOLTIP_ACCOUNT: "계정",
    UI_TEXT_TOOLTIP_PROXY: "툴박스/프록시",
    UI_TEXT_TOOLTIP_SETTINGS: "설정",
    UI_TEXT_TOOLTIP_MINIMIZE: "최소화",
    UI_TEXT_TOOLTIP_CLOSE: "닫기",
    UI_TEXT_BANMODAL_TITLE: "Account Banned",
    UI_TEXT_BANMODAL_MESSAGE: "Your account has been banned due to a violation of our Terms of Service and/or game rules.\n\nReason: {banReason}\nEnds At: {banExpirationDate}",
    UI_TEXT_BANMODAL_READ_RULES: "Please take some time to review the game rules below. They are always available in our discord server.",
    UI_TEXT_BANMODAL_ACKNOWLEDGE_MESSAGE: "Please type <strong>'I have read and understood the game rules'</strong> to continue. You will not be able to play until you understand the rules, even if the ban has already expired.",
    UI_TEXT_BANMODAL_ACKNOWLEDGE_REQUIRED_MESSAGE: "I have read and understood the game rules",
    UI_TEXT_BANMODAL_ACKNOWLEDGE_COMPLETE: "Please take some time to review the game rules in our discord server and wait for the ban to expire.",
    UI_TEXT_BANMODAL_ACKNOWLEDGE_BUTTON: "I Understand",
    UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS: {
      "1": "Violation of Game Rule #1 (Disallowed Skill Prediction Module)",
      "2": "Violation of Game Rule #2 (Botting)",
      "3": "Violation of Game Rule #3 (Non-Macro Skill Automation)",
      "4": "Violation of Game Rule #4 (Cheating Animation Speed / Attack Speed)",
      "5": "Violation of Game Rule #5 (Skill Replacer)",
      "6": "Violation of Game Rule #6 (Invincibility Cheating)",
      "7": "Violation of Game Rule #7 (Cheating Projectiles)",
      "8": "Violation of Game Rule #8 (Inhuman Actions)",
      "9": "Violation of Game Rule #9 (Bug, Exploit or Glitch Abuse)",
      "10": "Violation of Game Rule #10 (Teleportation Abuse)",
      "11": "Violation of Game Rule #11 (Holding Gameplay Hostage)",
      "12": "Violation of Game Rule #12 (Kick Abuse)",
      "13": "Violation of Game Rule #13 (Automating Cleric)",
      "14": "Violation of Game Rule #14 (PvP Interference)",
      "101": "Management Deliberation, Contact Support",
      "102": "Multiple Game Rule Violations, Contact Support",
      "0": "Other Terms of Service Violations, Contact Support",
    },
    UI_TEXT_TERMINATION_ERROR_MESSAGES: {
      "5": "종료 코드 5: DirectX를 찾을 수 없거나 손상되었거나 잘못된 버전이 설치되었습니다.",
      "6": "종료 코드 6: 데이터 센터에 잘못된 데이터가 있습니다.",
      "8": "종료 코드 8: 네트워크 오류가 발생했습니다.",
      "9": "종료 코드 9: 클라이언트 인증에 실패하였습니다. 다시 시도해 주세요.",
      "10": "종료 코드 10: 메모리가 부족합니다.",
      "11": "종료 코드 11: DirectX 초기화에 실패했습니다.",
      "12": "종료 코드 12: 사용 중인 그래픽 카드가 지원하지 않거나 문제가 발생했습니다.",
      "13": "종료 코드 13: 정시간동안 동작이 없어 게임이 종료되었습니다.",
      "16": "종료 코드 16: 서버에 의해 연결이 종료되었습니다.",
      "33": "종료 코드 33: 서버에 의해 연결이 종료되었습니다.",
      "34": "종료 코드 34: 서버에 의해 연결이 종료되었습니다.",
      "257": "종료 코드 257: 로그인 인증 키가 일치하지 않습니다.",
      "259": "종료 코드 259: 이미 접속된 계정입니다.",
      "265": "종료 코드 265: 클라이언트가 손상되었습니다.",
      "275": "종료 코드 275: 클라이언트를 실행할 수 없습니다.",
      "-1": "클라이언트가 비정상적으로 종료되었습니다.",
      unknown: "클라이언트가 종료되었습니다.",
    },
    UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES: {
      "401": "자동 로그인 인증이 만료되었습니다. 다시 로그인해주세요.",
      "503": "현재 서버 점검이 진행 중입니다.",
      "1000": "게임을 시작할 수 없습니다. Win32 오류 코드 {win32ErrorCode}.",
      "4031": "귀하의 계정은 서비스 약관 위반으로 인해 {banExpirationDate}일까지 게임 접속이 제한되었습니다. 자세한 내용은 게임 지원팀으로 문의하시기 바랍니다.",
      "4032": "의심스러운 행동. VPN 서비스를 비활성화하고 다시 시도하십시오. 자세한 내용은 게임 지원팀에 문의하십시오.",
      unknown: "SLS 오류. 서버 목록을 검색할 수 없거나 오류가 있습니다.",
    },
  },
  // en: {
  //   UI_TEXT_UPDATE_CHECK_FOR_UPDATES: "Checking for updates...",
  //   UI_TEXT_UPDATE_DOWNLOADING_UPDATE: "Downloading update {percentage}%...",
  //   UI_TEXT_UPDATE_VERIFYING_INTEGRITY: "Verifying integrity...",
  //   UI_TEXT_UPDATE_PREPARING_TO_INSTALL: "Preparing to install update...",
  //   UI_TEXT_UPDATE_INSTALLING_UPDATE: "Installing update...",
  //   UI_TEXT_UPDATE_FAILED: "Failed to update. Error: {error}",
  //   UI_TEXT_WELCOME_USERNAME: "Welcome, {username}",
  //   UI_TEXT_LOG_OUT: "Log Out",
  //   UI_TEXT_PATCH_PROGRESS_COMPLETED: "Completed",
  //   UI_TEXT_PATCH_PROGRESS_CHECK_FOR_UPDATES: "Checking for updates...",
  //   UI_TEXT_PATCH_PROGRESS_RETRIEVING_INFO: "Retrieving patch information...",
  //   UI_TEXT_PATCH_PROGRESS_PREALLOCATING: "Preallocating storage space...",
  //   UI_TEXT_PATCH_PROGRESS_CHECKING_EXISTING: "Checking existing files. Please wait. This may take a while...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOADING_FILES: "Downloading Files {percentage}%... ({downloadSize}/{totalSize} - {downloadSpeed} - ETA: {timeRemaining})",
  //   UI_TEXT_PATCH_PROGRESS_EXTRACTING_FILES: "Extracting files {percentage}%...",
  //   UI_TEXT_PATCH_PROGRESS_CLEANING_UP: "Cleaning up...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOAD_PAUSED: "Download paused {percentage}%. ({downloadSize}/{totalSize})",
  //   UI_TEXT_PATCH_PROGRESS_FAILED: "Failed to patch. An error has occurred. Please restart the launcher and try again.",
  //   UI_TEXT_PATCH_PROGRESS_REMOVE_LEGACY_INSTALL: "Removing legacy installation. Please wait...",
  //   UI_TEXT_LAUNCH_BUTTON_LOG_IN: "Log In",
  //   UI_TEXT_LAUNCH_BUTTON_PLAY: "Play",
  //   UI_TEXT_LAUNCH_BUTTON_LAUNCHING: "Launching...",
  //   UI_TEXT_LAUNCH_BUTTON_GAME_RUNNING: "Game Running",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_PAUSE: "Pause",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_RESUME: "Resume",
  //   UI_TEXT_LOGIN_MODAL_REMEMBER_ME: "Stay Logged In",
  //   UI_TEXT_LOGIN_MODAL_PASSWORD_RESET: "Forgot Password?",
  //   UI_TEXT_LOGIN_MODAL_CREATE_NEW_ACCOUNT: "Register Now",
  //   UI_TEXT_LOGIN_FAIL_INVALID_USERNAME_PASSWORD: "Invalid username or password.",
  //   UI_TEXT_LOGIN_FAIL_ACCOUNT_NOT_ACTIVATED: "Account not activated. Please check your email for the activation link.",
  //   UI_TEXT_LOGIN_FAIL_UNEXPECTED_ERROR: "Unexpected error. Please try again.",
  //   UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT: "Unable to connect to the server. Please try again.",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_CHECK_FOR_UPDATES: "Check For Game Updates",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_REPAIR_GAME: "Repair Game",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_OPEN_GAME_FOLDER: "Open Game Folder",
  //   UI_TEXT_LOGIN_MODAL_LOG_IN: "Log In",
  //   UI_TEXT_NOTICE_MODAL_TITLE: "Notice",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_TITLE: "Client Repair",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_MESSAGE: "This will repair your client. The process may take a while. Are you sure you want to continue?",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_BUTTON_START: "Start Repair",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_OK: "Dismiss",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_CANCEL: "Cancel",
  //   UI_TEXT_TOS_MODAL_TITLE: "Terms of Service and Privacy Policy",
  //   UI_TEXT_TOS_MODAL_DESCRIPTION: "Please read our Terms of Service and Privacy Policy on the links below before continuing. By clicking Accept, you indicate that you understand and agree to the provisions therein.",
  //   UI_TEXT_TOS_MODAL_CLICK_BELOW_MESSAGE: "Click below to view the Terms of Service and Privacy Policy.",
  //   UI_TEXT_TOS_MODAL_TOS_CHECKBOX: "Terms of Service",
  //   UI_TEXT_TOS_MODAL_PRIVACY_CHECKBOX: "Privacy Policy",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_ACCEPT: "Accept",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_DECLINE: "Decline",
  //   UI_TEXT_CONNECTION_ERROR: "Connection Error. Please try again.",
  //   UI_TEXT_UNEXPECTED_SERVER_RESPONSE: "Unexpected Server Response ({errorCode})",
  //   UI_TEXT_SETTINGS_MODAL_TITLE: "Settings",
  //   UI_TEXT_SETTINGS_LANG_SELECT_TITLE: "Game Language",
  //   UI_TEXT_SETTINGS_LIMIT_DOWNLOAD_TITLE: "Download Speed Limit",
  //   UI_TEXT_SETTINGS_LAUNCHERV_TITLE: "Launcher Version",
  //   UI_TEXT_SETTINGS_LAUNCHERV_VERSION: "Version {version}",
  //   UI_TEXT_SETTINGS_ABOUT_TITLE: "About",
  //   UI_TEXT_SETTINGS_LAUNCHERV_UPDATE_BUTTON: "Update Launcher",
  //   UI_TEXT_SETTINGS_LAUNCHERV_REPAIR_BUTTON: "Repair Launcher",
  //   UI_TEXT_SETTINGS_ABOUT_TOS: "Terms of Service",
  //   UI_TEXT_SETTINGS_ABOUT_PRIVACY: "Privacy Policy",
  //   UI_TEXT_SETTINGS_ABOUT_SERVICE_STATUS: "Service Status",
  //   UI_TEXT_TOOLTIP_ACCOUNT: "Account",
  //   UI_TEXT_TOOLTIP_PROXY: "Toolbox/Proxy",
  //   UI_TEXT_TOOLTIP_SETTINGS: "Settings",
  //   UI_TEXT_TOOLTIP_MINIMIZE: "Minimize",
  //   UI_TEXT_TOOLTIP_CLOSE: "Close",
  //   UI_TEXT_BANMODAL_TITLE: "Account Banned",
  //   UI_TEXT_BANMODAL_MESSAGE: "Your account has been banned due to a violation of our Terms of Service and/or game rules.\n\nReason: {banReason}\nEnds At: {banExpirationDate}",
  //   UI_TEXT_BANMODAL_READ_RULES: "Please take some time to review the game rules below. They are always available in our discord server.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_MESSAGE: "Please type <strong>'I have read and understood the game rules'</strong> to continue. You will not be able to play until you understand the rules, even if the ban has already expired.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_REQUIRED_MESSAGE: "I have read and understood the game rules",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_COMPLETE: "Please take some time to review the game rules in our discord server and wait for the ban to expire.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_BUTTON: "I Understand",
  //   UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS: {
  //     "1": "Violation of Game Rule #1 (Disallowed Skill Prediction Module)",
  //     "2": "Violation of Game Rule #2 (Botting)",
  //     "3": "Violation of Game Rule #3 (Non-Macro Skill Automation)",
  //     "4": "Violation of Game Rule #4 (Cheating Animation Speed / Attack Speed)",
  //     "5": "Violation of Game Rule #5 (Skill Replacer)",
  //     "6": "Violation of Game Rule #6 (Invincibility Cheating)",
  //     "7": "Violation of Game Rule #7 (Cheating Projectiles)",
  //     "8": "Violation of Game Rule #8 (Inhuman Actions)",
  //     "9": "Violation of Game Rule #9 (Bug, Exploit or Glitch Abuse)",
  //     "10": "Violation of Game Rule #10 (Teleportation Abuse)",
  //     "11": "Violation of Game Rule #11 (Holding Gameplay Hostage)",
  //     "12": "Violation of Game Rule #12 (Kick Abuse)",
  //     "13": "Violation of Game Rule #13 (Automating Cleric)",
  //     "14": "Violation of Game Rule #14 (PvP Interference)",
  //     "101": "Management Deliberation, Contact Support",
  //     "102": "Multiple Game Rule Violations, Contact Support",
  //     "0": "Other Terms of Service Violations, Contact Support",
  //   },
  //   UI_TEXT_TERMINATION_ERROR_MESSAGES: {
  //     "5": "Termination Code 5: DirectX could not be found, is corrupt or an incorrect version is installed.",
  //     "6": "Termination Code 6: The database contains invalid data.",
  //     "8": "Termination Code 8: A network error has occurred.",
  //     "9": "Termination Code 9: Session Ticket not received. The launcher did not respond to the ticket request within 5 seconds.",
  //     "10": "Termination Code 10: Insufficient RAM.",
  //     "11": "Termination Code 11: Failed to reset DirectX.",
  //     "12": "Termination Code 12: The graphics card in use is unsuitable, too old or unknown.",
  //     "13": "Termination Code 13: The game was closed due to inactivity.",
  //     "16": "Termination Code 16: Your connection was terminated by the server.",
  //     "33": "Termination Code 33: Your connection was terminated by the server.",
  //     "34": "Termination Code 34: Your connection was terminated by the server.",
  //     "257": "Termination Code 257: Login error. The server could not confirm your login.",
  //     "259": "Termination Code 259: Double login. Another client is logged in using your account.",
  //     "265": "Termination Code 265: Client corrupted. Repair the client using the launcher's repair function.",
  //     "275": "Termination Code 275: TERA.exe could not be launched.",
  //     "-1": "The client was closed abnormally.",
  //     unknown: "The client was closed. Please try again.",
  //   },
  //   UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES: {
  //     "401": "Your automatic login token has expired. Please log in again.",
  //     "503": "The game is currently in maintenance.",
  //     "1000": "Game could not be started. Win32 Error Code {win32ErrorCode}.",
  //     "4031": "Your account has been restricted from accessing the game until {banExpirationDate} due to a violation of our Terms of Service. For more information, please contact game support.",
  //     "4032": "Suspicious Behavior. Please disable any VPN services and try again. For more information, please contact game support.",
  //     unknown: "SLS error. Server list cannot be retrieved or contains errors.",
  //   },
  // },
  // de: {
  //   UI_TEXT_UPDATE_CHECK_FOR_UPDATES: "Prüfe nach updates...",
  //   UI_TEXT_UPDATE_DOWNLOADING_UPDATE: "Lädt herunter ... {percentage}%...",
  //   UI_TEXT_UPDATE_VERIFYING_INTEGRITY: "Überprüfe Integrität...",
  //   UI_TEXT_UPDATE_PREPARING_TO_INSTALL: "Bereite Installation vor...",
  //   UI_TEXT_UPDATE_INSTALLING_UPDATE: "Installiere Update...",
  //   UI_TEXT_UPDATE_FAILED: "Update fehlgeschlagen. Error: {error}",
  //   UI_TEXT_WELCOME_USERNAME: "Willkommen, {username}",
  //   UI_TEXT_LOG_OUT: "Ausloggen",
  //   UI_TEXT_PATCH_PROGRESS_COMPLETED: "Fertig",
  //   UI_TEXT_PATCH_PROGRESS_CHECK_FOR_UPDATES: "Such nach updates...",
  //   UI_TEXT_PATCH_PROGRESS_RETRIEVING_INFO: "Ruft Patch-Infos ab...",
  //   UI_TEXT_PATCH_PROGRESS_PREALLOCATING: "Belegt Festplatten-Speicher vor...",
  //   UI_TEXT_PATCH_PROGRESS_CHECKING_EXISTING: "Suche nach vorhandenen Dateien. Bitte warten. Das kann kurz dauern...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOADING_FILES: "Lädt herunter {percentage}%... ({downloadSize}/{totalSize} - {downloadSpeed} - Ca.: {timeRemaining})",
  //   UI_TEXT_PATCH_PROGRESS_EXTRACTING_FILES: "Extrahiere {percentage}%...",
  //   UI_TEXT_PATCH_PROGRESS_CLEANING_UP: "Räumt auf...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOAD_PAUSED: "Download pausiert {percentage}%. ({downloadSize}/{totalSize})",
  //   UI_TEXT_PATCH_PROGRESS_FAILED: "Aktualisierung ist fehlgeschlagen. Eine Störung ist aufgetreten. Bitte den Launcher neu starten.",
  //   UI_TEXT_PATCH_PROGRESS_REMOVE_LEGACY_INSTALL: "Entferne Original-Installation. Bitte warten...",
  //   UI_TEXT_LAUNCH_BUTTON_LOG_IN: "Einloggen",
  //   UI_TEXT_LAUNCH_BUTTON_PLAY: "Spielen",
  //   UI_TEXT_LAUNCH_BUTTON_LAUNCHING: "Starte Spiel...",
  //   UI_TEXT_LAUNCH_BUTTON_GAME_RUNNING: "Spiel läuft",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_PAUSE: "Pause",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_RESUME: "Fortsetzen",
  //   UI_TEXT_LOGIN_MODAL_REMEMBER_ME: "Eingeloggt bleiben",
  //   UI_TEXT_LOGIN_MODAL_PASSWORD_RESET: "Passwort vergessen?",
  //   UI_TEXT_LOGIN_MODAL_CREATE_NEW_ACCOUNT: "Registrieren",
  //   UI_TEXT_LOGIN_FAIL_INVALID_USERNAME_PASSWORD: "Ungültiger Nutzername oder Passwort.",
  //   UI_TEXT_LOGIN_FAIL_ACCOUNT_NOT_ACTIVATED: "Account wurde noch nicht aktiviert. Bitte überprüfen Sie Ihre E-Mail nach dem Aktivierunglink.",
  //   UI_TEXT_LOGIN_FAIL_UNEXPECTED_ERROR: "Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es erneut.",
  //   UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT: "Server-Verbindung fehlgeschlagen. Versuchen Sie es erneut.",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_CHECK_FOR_UPDATES: "Nach updates prüfen",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_REPAIR_GAME: "Daten reparieren",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_OPEN_GAME_FOLDER: "Installations-Ordner öffnen",
  //   UI_TEXT_LOGIN_MODAL_LOG_IN: "Einloggen",
  //   UI_TEXT_NOTICE_MODAL_TITLE: "Mitteilung",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_TITLE: "Launcher reparieren",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_MESSAGE: "Dadurch wird Ihr Launcher repariert. Das kann eine Weile dauern. Sind Sie sicher, dass Sie Fortfahren möchten?",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_BUTTON_START: "Reparatur starten",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_OK: "Pause",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_CANCEL: "Abbrechen",
  //   UI_TEXT_TOS_MODAL_TITLE: "Nutzungsbedingungen und Datenschutz",
  //   UI_TEXT_TOS_MODAL_DESCRIPTION: "Bitte lesen Sie die Nutzungsbedingungen und Datenschutzrichtlinien unter den folgenden Links bevor Sie Fortfahren. Indem Sie auf „Akzeptieren“ klicken, erklären Sie, dass Sie die darin enthaltenen Richtlinien verstanden haben und ihnen zustimmen.",
  //   UI_TEXT_TOS_MODAL_CLICK_BELOW_MESSAGE: "Click below to view the Terms of Service and Privacy Policy.",
  //   UI_TEXT_TOS_MODAL_TOS_CHECKBOX: "Nutzungsbedingungen",
  //   UI_TEXT_TOS_MODAL_PRIVACY_CHECKBOX: "Datenschutz",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_ACCEPT: "Aktzeptieren",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_DECLINE: "Ablehnen",
  //   UI_TEXT_CONNECTION_ERROR: "Verbindungsfehler. Versuchen Sie es erneut.",
  //   UI_TEXT_UNEXPECTED_SERVER_RESPONSE: "unerwartete Serverantwort ({errorCode})",
  //   UI_TEXT_SETTINGS_MODAL_TITLE: "Einstellungen",
  //   UI_TEXT_SETTINGS_LANG_SELECT_TITLE: "Sprache",
  //   UI_TEXT_SETTINGS_LIMIT_DOWNLOAD_TITLE: "Geschwindigkeitsbegrenzung lädt herunter",
  //   UI_TEXT_SETTINGS_LAUNCHERV_TITLE: "Launcher Version",
  //   UI_TEXT_SETTINGS_LAUNCHERV_VERSION: "Version {version}",
  //   UI_TEXT_SETTINGS_ABOUT_TITLE: "Über",
  //   UI_TEXT_SETTINGS_LAUNCHERV_UPDATE_BUTTON: "Aktualisiere Launcher",
  //   UI_TEXT_SETTINGS_LAUNCHERV_REPAIR_BUTTON: "Repariere Launcher",
  //   UI_TEXT_SETTINGS_ABOUT_TOS: "Nutzungsbedingungen",
  //   UI_TEXT_SETTINGS_ABOUT_PRIVACY: "Datenschutz",
  //   UI_TEXT_SETTINGS_ABOUT_SERVICE_STATUS: "Service Status",
  //   UI_TEXT_TOOLTIP_ACCOUNT: "Account",
  //   UI_TEXT_TOOLTIP_PROXY: "Toolbox/Proxy",
  //   UI_TEXT_TOOLTIP_SETTINGS: "Einstellungen",
  //   UI_TEXT_TOOLTIP_MINIMIZE: "Minimieren",
  //   UI_TEXT_TOOLTIP_CLOSE: "Schließen",
  //   UI_TEXT_BANMODAL_TITLE: "Konto Gesperrt",
  //   UI_TEXT_BANMODAL_MESSAGE: "Ihr Konto wurde aufgrund eines Verstoßes gegen unsere Nutzungsbedingungen und/oder Spielregeln gesperrt.\n\nGrund: {banReason}\nEndet am: {banExpirationDate}",
  //   UI_TEXT_BANMODAL_READ_RULES: "Bitte nehmen Sie sich etwas Zeit, um die unten stehenden Spielregeln zu überprüfen. Sie sind immer auf unserem Discord-Server verfügbar.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_MESSAGE: "Bitte tippen Sie <strong>'Ich habe die Spielregeln gelesen und verstanden'</strong>, um fortzufahren. Sie können nicht spielen, bis Sie die Regeln verstanden haben, auch wenn die Sperre bereits abgelaufen ist.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_REQUIRED_MESSAGE: "Ich habe die Spielregeln gelesen und verstanden",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_COMPLETE: "Bitte nehmen Sie sich die Zeit, die Spielregeln auf unserem Discord-Server zu überprüfen und warten Sie, bis die Sperre abläuft.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_BUTTON: "Ich verstehe",
  //   UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS: {
  //     "1": "Verstoß gegen Spielregel #1 (Unzulässiges Skill Prediction Modul)",
  //     "2": "Verstoß gegen Spielregel #2 (Botting)",
  //     "3": "Verstoß gegen Spielregel #3 (Nicht-Makro-Skill-Automatisierung)",
  //     "4": "Verstoß gegen Spielregel #4 (Cheating Animation Geschwindigkeit / Angriffsgeschwindigkeit)",
  //     "5": "Verstoß gegen Spielregel #5 (Skill Replacer)",
  //     "6": "Verstoß gegen Spielregel #6 (Unbesiegbarkeit Schummeln)",
  //     "7": "Verstoß gegen Spielregel #7 (Schummeln mit Projektilen)",
  //     "8": "Verstoß gegen Spielregel #8 (Unmenschliche Aktionen)",
  //     "9": "Verstoß gegen Spielregel #9 (Fehler, Exploit oder Glitch Missbrauch)",
  //     "10": "Verstoß gegen Spielregel #10 (Teleportationsmissbrauch)",
  //     "11": "Verstoß gegen Spielregel #11 (Geiselhaft des Gameplays)",
  //     "12": "Verstoß gegen Spielregel #12 (Kick-Missbrauch)",
  //     "13": "Verstoß gegen Spielregel #13 (Automatisierung des Klerikers)",
  //     "14": "Verstoß gegen Spielregel #14 (PvP-Interferenz)",
  //     "101": "Verwaltungsberatung, Kontaktieren Sie den Support",
  //     "102": "Mehrere Spielregelverstöße, Kontaktieren Sie den Support",
  //     "0": "Andere Verstöße gegen die Nutzungsbedingungen, Kontaktieren Sie den Support",
  //   },
  //   UI_TEXT_TERMINATION_ERROR_MESSAGES: {
  //     "5": "Termination Code 5: DirectX konnte nicht gefunden werden, ist beschädigt oder eine falsche Version installiert.",
  //     "6": "Termination Code 6: Die Datenbank enthält ungültige Daten.",
  //     "8": "Termination Code 8: Ein Netzwerkfehler ist aufgetreten.",
  //     "9": "Termination Code 9: Sitzungsticket nicht erhalten. Der Launcher hat nicht innerhalb von 5 Sekunden auf die Ticketanfrage geantwortet.",
  //     "10": "Termination Code 10: Ungenügend RAM.",
  //     "11": "Termination Code 11: Fehler beim Zurücksetzen von DirectX.",
  //     "12": "Termination Code 12: Die verwendete Grafikkarte ist ungeeignet, zu alt oder unbekannt.",
  //     "13": "Termination Code 13: Das Spiel wurde wegen Inaktivität geschlossen.",
  //     "16": "Termination Code 16: Ihre Verbindung wurde vom Server getrennt.",
  //     "33": "Termination Code 33: Ihre Verbindung wurde vom Server getrennt.",
  //     "34": "Termination Code 34: Ihre Verbindung wurde vom Server getrennt.",
  //     "257": "Termination Code 257: Login-Fehler. Der Server konnte Ihre Anmeldung nicht bestätigen.",
  //     "259": "Termination Code 259: Doppelter Login. Ein anderer client ist mit Ihrem Konto angemeldet.",
  //     "265": "Termination Code 265: Client beschädigt. Reparieren Sie den Client mit der Reparaturfunktion des Launchers.",
  //     "275": "Termination Code 275: TERA.exe konnte nicht gestartet werden.",
  //     "-1": "Der Client wurde abnormal geschlossen.",
  //     unknown: "Der Client wurde geschlossen. Bitte versuchen Sie es erneut.",
  //   },
  //   UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES: {
  //     "401": "Ihr automatisches Login-Token ist abgelaufen. Bitte melden Sie sich erneut an.",
  //     "503": "Das Spiel befindet sich derzeit in der Wartung.",
  //     "1000": "Das Spiel konnte nicht gestartet werden. Win32 Error Code {win32ErrorCode}.",
  //     "4031": "Ihr account wurde aufgrund eines Verstoßes gegen unsere Nutzungsbedingungen bis zum {banExpirationDate} gesperrt. Für weitere Informationen, wenden Sie sich bitte an den Spielesupport",
  //     "4032": "Verdächtiges Verhalten. Bitte deaktivieren Sie alle VPN-Dienste und versuchen Sie es erneut. Für weitere Informationen, wenden Sie sich bitte an den Spielesupport",
  //     unknown: "SLS-Fehler. Serverliste kann nicht abgerufen werden oder enthält Fehler.",
  //   },
  // },
  // fr: {
  //   UI_TEXT_UPDATE_CHECK_FOR_UPDATES: "Recherche de mise à jour...",
  //   UI_TEXT_UPDATE_DOWNLOADING_UPDATE: "Téléchargement de la mise à jour {percentage}%...",
  //   UI_TEXT_UPDATE_VERIFYING_INTEGRITY: "Vérification de l'intégrité des fichiers...",
  //   UI_TEXT_UPDATE_PREPARING_TO_INSTALL: "Préparation à l'installation...",
  //   UI_TEXT_UPDATE_INSTALLING_UPDATE: "Installation de la mise à jour...",
  //   UI_TEXT_UPDATE_FAILED: "Échec de la mise à jour. Erreur: {error}",
  //   UI_TEXT_WELCOME_USERNAME: "Bienvenue, {username}",
  //   UI_TEXT_LOG_OUT: "Déconnexion",
  //   UI_TEXT_PATCH_PROGRESS_COMPLETED: "Terminé",
  //   UI_TEXT_PATCH_PROGRESS_CHECK_FOR_UPDATES: "Recherche de mise à jour...",
  //   UI_TEXT_PATCH_PROGRESS_RETRIEVING_INFO: "Récupération des informations de la mise à jour...",
  //   UI_TEXT_PATCH_PROGRESS_PREALLOCATING: "Allocation de l'espace de stockage...",
  //   UI_TEXT_PATCH_PROGRESS_CHECKING_EXISTING: "Vérification des fichiers existant. Merci de patienter. Cela peut prendre un certain temps...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOADING_FILES: "Téléchargement des fichiers {percentage}%... ({downloadSize}/{totalSize} - {downloadSpeed} - Temps Restant: {timeRemaining})",
  //   UI_TEXT_PATCH_PROGRESS_EXTRACTING_FILES: "Extraction des fichiers {percentage}%...",
  //   UI_TEXT_PATCH_PROGRESS_CLEANING_UP: "Nettoyage...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOAD_PAUSED: "Téléchargement en pause {percentage}%. ({downloadSize}/{totalSize})",
  //   UI_TEXT_PATCH_PROGRESS_FAILED: "Echec de la mise à jour. Une erreur est survenue. Veuillez redémarrer le lanceur et réessayer.",
  //   UI_TEXT_PATCH_PROGRESS_REMOVE_LEGACY_INSTALL: "Suppression de l'installation existante. Veuillez patienter...",
  //   UI_TEXT_LAUNCH_BUTTON_LOG_IN: "Connexion",
  //   UI_TEXT_LAUNCH_BUTTON_PLAY: "Jouer",
  //   UI_TEXT_LAUNCH_BUTTON_LAUNCHING: "Démarrage...",
  //   UI_TEXT_LAUNCH_BUTTON_GAME_RUNNING: "Jeu en marche",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_PAUSE: "Pause",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_RESUME: "Reprendre",
  //   UI_TEXT_LOGIN_MODAL_REMEMBER_ME: "Rester connecté",
  //   UI_TEXT_LOGIN_MODAL_PASSWORD_RESET: "Mot de passe oublié?",
  //   UI_TEXT_LOGIN_MODAL_CREATE_NEW_ACCOUNT: "Créer un compte",
  //   UI_TEXT_LOGIN_FAIL_INVALID_USERNAME_PASSWORD: "Nom d'utilisateur ou mot de passe incorrect.",
  //   UI_TEXT_LOGIN_FAIL_ACCOUNT_NOT_ACTIVATED: "Votre compte n'est pas activé. Vérifiez vos mails et trouvez le lien d'activation.",
  //   UI_TEXT_LOGIN_FAIL_UNEXPECTED_ERROR: "Erreur inattendue. Veuillez réessayer.",
  //   UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT: "Impossible de se connecter au serveur. Veuillez réessayer.",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_CHECK_FOR_UPDATES: "Rechercher une mise à jour",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_REPAIR_GAME: "Réparer le jeu",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_OPEN_GAME_FOLDER: "Ouvrir le dossier du jeu",
  //   UI_TEXT_LOGIN_MODAL_LOG_IN: "Connexion",
  //   UI_TEXT_NOTICE_MODAL_TITLE: "Notice",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_TITLE: "Réparer le client",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_MESSAGE: "Cette action va réparer votre client. Cela peut prendre un certain temps. Êtes-vous sur de vouloir continuer?",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_BUTTON_START: "Commencer la réparation",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_OK: "OK",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_CANCEL: "Annuler",
  //   UI_TEXT_TOS_MODAL_TITLE: "Conditions d'utilisation et politique de confidentialité",
  //   UI_TEXT_TOS_MODAL_DESCRIPTION: "Veuillez lire nos Conditions d'utilisation et politique de confidentialité présentes sur les liens en dessous avant de continuer. En cliquant sur Accepter, vous indiquez que vous comprenez et acceptez les dispositions qui y sont énoncées",
  //   UI_TEXT_TOS_MODAL_CLICK_BELOW_MESSAGE: "Cliquez en dessous pour voir nos Conditions d'utilisation et politique de confidentialité",
  //   UI_TEXT_TOS_MODAL_TOS_CHECKBOX: "Conditions d'utilisation",
  //   UI_TEXT_TOS_MODAL_PRIVACY_CHECKBOX: "Politique de confidentialité",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_ACCEPT: "Accepter",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_DECLINE: "Rejeter",
  //   UI_TEXT_CONNECTION_ERROR: "Erreur de connexion. Veuillez réessayer.",
  //   UI_TEXT_UNEXPECTED_SERVER_RESPONSE: "Réponse inattendue du serveur ({errorCode})",
  //   UI_TEXT_SETTINGS_MODAL_TITLE: "Option",
  //   UI_TEXT_SETTINGS_LANG_SELECT_TITLE: "Langue",
  //   UI_TEXT_SETTINGS_LIMIT_DOWNLOAD_TITLE: "Vitesse de téléchargement maximale",
  //   UI_TEXT_SETTINGS_LAUNCHERV_TITLE: "Version du client",
  //   UI_TEXT_SETTINGS_LAUNCHERV_VERSION: "Version {version}",
  //   UI_TEXT_SETTINGS_ABOUT_TITLE: "À propos",
  //   UI_TEXT_SETTINGS_LAUNCHERV_UPDATE_BUTTON: "Mettre à jour le client",
  //   UI_TEXT_SETTINGS_LAUNCHERV_REPAIR_BUTTON: "Réparer le client",
  //   UI_TEXT_SETTINGS_ABOUT_TOS: "Conditions d'utilisation",
  //   UI_TEXT_SETTINGS_ABOUT_PRIVACY: "Politique de confidentialité",
  //   UI_TEXT_SETTINGS_ABOUT_SERVICE_STATUS: "Statut du service",
  //   UI_TEXT_TOOLTIP_ACCOUNT: "Compte",
  //   UI_TEXT_TOOLTIP_PROXY: "Toolbox/Proxy",
  //   UI_TEXT_TOOLTIP_SETTINGS: "Options",
  //   UI_TEXT_TOOLTIP_MINIMIZE: "Minimiser",
  //   UI_TEXT_TOOLTIP_CLOSE: "Fermer",
  //   UI_TEXT_BANMODAL_TITLE: "Compte Banni",
  //   UI_TEXT_BANMODAL_MESSAGE: "Votre compte a été banni en raison d'une violation de nos Conditions d'utilisation et/ou des règles du jeu.\n\nRaison : {banReason}\nSe termine le : {banExpirationDate}",
  //   UI_TEXT_BANMODAL_READ_RULES: "Veuillez prendre le temps de revoir les règles du jeu ci-dessous. Elles sont toujours disponibles sur notre serveur Discord.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_MESSAGE: "Veuillez taper <strong>« J'ai lu et compris les règles du jeu »</strong> pour continuer. Vous ne pourrez pas jouer tant que vous n'aurez pas compris les règles, même si le bannissement a déjà expiré.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_REQUIRED_MESSAGE: "J'ai lu et compris les règles du jeu",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_COMPLETE: "Veuillez prendre le temps de revoir les règles du jeu sur notre serveur Discord et attendez l'expiration du bannissement.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_BUTTON: "Je comprends",
  //   UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS: {
  //     "1": "Violation de la règle du jeu n°1 (Module de Skill Prediction non autorisé)",
  //     "2": "Violation de la règle du jeu n°2 (Botting)",
  //     "3": "Violation de la règle du jeu n°3 (Automatisation des compétences non-macro)",
  //     "4": "Violation de la règle du jeu n°4 (Vitesse d'animation / Vitesse d'attaque triche)",
  //     "5": "Violation de la règle du jeu n°5 (Skill Replacer)",
  //     "6": "Violation de la règle du jeu n°6 (Tricherie d'invincibilité)",
  //     "7": "Violation de la règle du jeu n°7 (Tricherie de projectiles)",
  //     "8": "Violation de la règle du jeu n°8 (Actions inhumaines)",
  //     "9": "Violation de la règle du jeu n°9 (Abus de bug, exploit ou glitch)",
  //     "10": "Violation de la règle du jeu n°10 (Abus de téléportation)",
  //     "11": "Violation de la règle du jeu n°11 (Prendre le jeu en otage)",
  //     "12": "Violation de la règle du jeu n°12 (Abus de kick)",
  //     "13": "Violation de la règle du jeu n°13 (Automatisation du Clerc)",
  //     "14": "Violation de la règle du jeu n°14 (Interférence en PvP)",
  //     "101": "Délibération de gestion, contactez le support",
  //     "102": "Multiples violations des règles du jeu, contactez le support",
  //     "0": "Autres violations des conditions d'utilisation, contactez le support",
  //   },
  //   UI_TEXT_TERMINATION_ERROR_MESSAGES: {
  //     "5": "Erreur Code 5: DirectX est introuvable, défectueux ou une version inadéquate est installée.",
  //     "6": "Erreur Code 6: La base de données contient des données invalides.",
  //     "8": "Erreur Code 8: Une erreur réseau s'est produite.",
  //     "9": "Erreur Code 9: Ticket de session non reçu. Le client n'a pas répondu à la demande de ticket dans les 5 secondes.",
  //     "10": "Erreur Code 10: Mémoire vive (RAM) insuffisante.",
  //     "11": "Erreur Code 11: Échec de la réinitialisation de DirectX.",
  //     "12": "Erreur Code 12: La carte graphique utilisée est inadaptée, trop ancienne ou inconnue.",
  //     "13": "Erreur Code 13: Le jeu a été fermé pour cause d'inactivité.",
  //     "16": "Erreur Code 16: Votre connexion a été interrompue par le serveur.",
  //     "33": "Erreur Code 33: Votre connexion a été interrompue par le serveur.",
  //     "34": "Erreur Code 34: Votre connexion a été interrompue par le serveur.",
  //     "257": "Erreur Code 257: Erreur de connexion. Le serveur n'a pas pu confirmer votre connexion.",
  //     "259": "Erreur Code 259: Double connexion. Un autre client est connecté avec votre compte.",
  //     "265": "Erreur Code 265: Client corrompu. Réparer le client à l'aide de la fonction de réparation du client.",
  //     "275": "Erreur Code 275: TERA.exe n'a pas pu être démarré.",
  //     "-1": "TERA s'est fermé anormalement.",
  //     unknown: "TERA s'est fermé. Merci de réessayer.",
  //   },
  //   UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES: {
  //     "401": "Votre jeton de connexion automatique a expiré. Veuillez vous reconnecter.",
  //     "503": "Le jeu est actuellement en maintenance.",
  //     "1000": "Le jeu n'a pas pu être lancé. Code d'erreur Win32 {win32ErrorCode}.",
  //     "4031": "Votre compte subit une restriction d'accès au jeu jusqu'au {banExpirationDate} pour violations de nos Conditions d'utilisation. Pour plus d'information, veuillez contacter le support.",
  //     "4032": "Comportement suspicieux. Veuillez désactiver votre VPN et réessayez. Pour plus d'information, veuillez contacter le support.",
  //     unknown: "Erreur SLS. La liste des serveurs n'a pas pu être récupérée ou contient des erreurs.",
  //   },
  // },
  // ru: {
  //   UI_TEXT_UPDATE_CHECK_FOR_UPDATES: "Проверка обновлений...",
  //   UI_TEXT_UPDATE_DOWNLOADING_UPDATE: "Загрузка обновлений {percentage}%...",
  //   UI_TEXT_UPDATE_VERIFYING_INTEGRITY: "Проверка целостности...",
  //   UI_TEXT_UPDATE_PREPARING_TO_INSTALL: "Подготовка к установке обновлений...",
  //   UI_TEXT_UPDATE_INSTALLING_UPDATE: "Установка обновлений...",
  //   UI_TEXT_UPDATE_FAILED: "Неудачное обновление. Ошибка: {error}",
  //   UI_TEXT_WELCOME_USERNAME: "Добро пожаловать, {username}",
  //   UI_TEXT_LOG_OUT: "Выйти",
  //   UI_TEXT_PATCH_PROGRESS_COMPLETED: "Завершено",
  //   UI_TEXT_PATCH_PROGRESS_CHECK_FOR_UPDATES: "Проверка обновлений...",
  //   UI_TEXT_PATCH_PROGRESS_RETRIEVING_INFO: "Получение информации об обновлениях...",
  //   UI_TEXT_PATCH_PROGRESS_PREALLOCATING: "Предварительное выделение места для хранения информации...",
  //   UI_TEXT_PATCH_PROGRESS_CHECKING_EXISTING: "Проверка существующих файлов. Пожалуйста, подождите. Это может занять некоторое время...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOADING_FILES: "Скачивание файлов {percentage}%... ({downloadSize}/{totalSize} - {downloadSpeed} - Примерное время загрузки: {timeRemaining})",
  //   UI_TEXT_PATCH_PROGRESS_EXTRACTING_FILES: "Извлечение файлов {percentage}%...",
  //   UI_TEXT_PATCH_PROGRESS_CLEANING_UP: "Очистка...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOAD_PAUSED: "Загрузка приостановлена {percentage}%. ({downloadSize}/{totalSize})",
  //   UI_TEXT_PATCH_PROGRESS_FAILED: "Не удалось выполнить обновление. Произошла ошибка. Пожалуйста, перезапустите игровой клиент и повторите попытку.",
  //   UI_TEXT_PATCH_PROGRESS_REMOVE_LEGACY_INSTALL: "Удаление устаревшей версии. Пожалуйста, подождите...",
  //   UI_TEXT_LAUNCH_BUTTON_LOG_IN: "Войти",
  //   UI_TEXT_LAUNCH_BUTTON_PLAY: "Играть",
  //   UI_TEXT_LAUNCH_BUTTON_LAUNCHING: "Запуск...",
  //   UI_TEXT_LAUNCH_BUTTON_GAME_RUNNING: "Игра запущена",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_PAUSE: "Пауза",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_RESUME: "Продолжить",
  //   UI_TEXT_LOGIN_MODAL_REMEMBER_ME: "Оставаться в системе",
  //   UI_TEXT_LOGIN_MODAL_PASSWORD_RESET: "Забыли пароль?",
  //   UI_TEXT_LOGIN_MODAL_CREATE_NEW_ACCOUNT: "Регистрация",
  //   UI_TEXT_LOGIN_FAIL_INVALID_USERNAME_PASSWORD: "Неправильное имя пользователя или пароль.",
  //   UI_TEXT_LOGIN_FAIL_ACCOUNT_NOT_ACTIVATED: "Учетная запись не активирована. Пожалуйста, проверьте свою электронную почту на наличие ссылки для активации.",
  //   UI_TEXT_LOGIN_FAIL_UNEXPECTED_ERROR: "Непредвиденная ошибка. Пожалуйста, попробуйте еще раз.",
  //   UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT: "Не удается подключиться к серверу. Пожалуйста, попробуйте еще раз.",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_CHECK_FOR_UPDATES: "Проверить наличие обновлений",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_REPAIR_GAME: "Восстановление игры",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_OPEN_GAME_FOLDER: "Открыть папку с игрой",
  //   UI_TEXT_LOGIN_MODAL_LOG_IN: "Войти",
  //   UI_TEXT_NOTICE_MODAL_TITLE: "Уведомления",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_TITLE: "Восстановление клиента",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_MESSAGE: "Это восстановит ваш клиент. Процесс может занять некоторое время. Вы уверены что хотите продолжить?",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_BUTTON_START: "Начать восстановление",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_OK: "Закрыть",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_CANCEL: "Отмена",
  //   UI_TEXT_TOS_MODAL_TITLE: "Условия обслуживания и Политика конфиденциальности",
  //   UI_TEXT_TOS_MODAL_DESCRIPTION: "Прежде чем продолжить, ознакомьтесь с нашими Условиями обслуживания и Политикой конфиденциальности по ссылкам ниже. Нажимая «Принять», вы указываете, что понимаете и соглашаетесь с настоящими Условиями.",
  //   UI_TEXT_TOS_MODAL_CLICK_BELOW_MESSAGE: "Нажмите ниже, чтобы ознакомиться с Условиями обслуживания и Политикой конфиденциальности.",
  //   UI_TEXT_TOS_MODAL_TOS_CHECKBOX: "Условия использования",
  //   UI_TEXT_TOS_MODAL_PRIVACY_CHECKBOX: "Политика конфиденциальности",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_ACCEPT: "Принять",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_DECLINE: "Отклонить",
  //   UI_TEXT_CONNECTION_ERROR: "Ошибка интернет-соединения. Пожалуйста, повторите снова.",
  //   UI_TEXT_UNEXPECTED_SERVER_RESPONSE: "Непредвиденный ответ сервера ({errorCode})",
  //   UI_TEXT_SETTINGS_MODAL_TITLE: "Настройки",
  //   UI_TEXT_SETTINGS_LANG_SELECT_TITLE: "Язык Игры",
  //   UI_TEXT_SETTINGS_LIMIT_DOWNLOAD_TITLE: "Ограничить скорость загрузки",
  //   UI_TEXT_SETTINGS_LAUNCHERV_TITLE: "Версия клиента",
  //   UI_TEXT_SETTINGS_LAUNCHERV_VERSION: "Версия {version}",
  //   UI_TEXT_SETTINGS_ABOUT_TITLE: "О нас",
  //   UI_TEXT_SETTINGS_LAUNCHERV_UPDATE_BUTTON: "Обновить клиент",
  //   UI_TEXT_SETTINGS_LAUNCHERV_REPAIR_BUTTON: "Восстановить клиент",
  //   UI_TEXT_SETTINGS_ABOUT_TOS: "Условия использования",
  //   UI_TEXT_SETTINGS_ABOUT_PRIVACY: "Политика конфиденциальности",
  //   UI_TEXT_SETTINGS_ABOUT_SERVICE_STATUS: "Статус сервера",
  //   UI_TEXT_TOOLTIP_ACCOUNT: "Аккаунт",
  //   UI_TEXT_TOOLTIP_PROXY: "Тулбокс/Прокси",
  //   UI_TEXT_TOOLTIP_SETTINGS: "Настройки",
  //   UI_TEXT_TOOLTIP_MINIMIZE: "Минимизировать",
  //   UI_TEXT_TOOLTIP_CLOSE: "Закрыть",
  //   UI_TEXT_BANMODAL_TITLE: "Аккаунт заблокирован",
  //   UI_TEXT_BANMODAL_MESSAGE: "Ваш аккаунт был заблокирован из-за нарушения наших условий использования и/или правил игры.\n\nПричина: {banReason}\nЗавершается: {banExpirationDate}",
  //   UI_TEXT_BANMODAL_READ_RULES: "Пожалуйста, уделите время для ознакомления с правилами игры ниже. Они всегда доступны на нашем сервере Discord.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_MESSAGE: "Пожалуйста, напишите <strong>«Я прочитал и понял правила игры»</strong>, чтобы продолжить. Вы не сможете играть, пока не поймёте правила, даже если бан уже истек.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_REQUIRED_MESSAGE: "Я прочитал и понял правила игры",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_COMPLETE: "Пожалуйста, уделите время для ознакомления с правилами игры на нашем сервере Discord и дождитесь окончания бана.",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_BUTTON: "Я понимаю",
  //   UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS: {
  //     "1": "Нарушение правила игры №1 (Недопустимый модуль Skill Prediction)",
  //     "2": "Нарушение правила игры №2 (Ботинг)",
  //     "3": "Нарушение правила игры №3 (Автоматизация навыков без макросов)",
  //     "4": "Нарушение правила игры №4 (Читинг скорости анимации / скорости атаки)",
  //     "5": "Нарушение правила игры №5 (Skill Replacer)",
  //     "6": "Нарушение правила игры №6 (Читинг неуязвимости)",
  //     "7": "Нарушение правила игры №7 (Читинг снарядов)",
  //     "8": "Нарушение правила игры №8 (Нечеловеческие действия)",
  //     "9": "Нарушение правила игры №9 (Использование багов, эксплойтов или глитчей)",
  //     "10": "Нарушение правила игры №10 (Злоупотребление телепортацией)",
  //     "11": "Нарушение правила игры №11 (Удержание игрового процесса в заложниках)",
  //     "12": "Нарушение правила игры №12 (Злоупотребление киком)",
  //     "13": "Нарушение правила игры №13 (Автоматизация клерика)",
  //     "14": "Нарушение правила игры №14 (Помехи в PvP)",
  //     "101": "Рассмотрение управления, обратитесь в поддержку",
  //     "102": "Множественные нарушения правил игры, обратитесь в поддержку",
  //     "0": "Прочие нарушения условий обслуживания, обратитесь в поддержку",
  //   },
  //   UI_TEXT_TERMINATION_ERROR_MESSAGES: {
  //     "5": "Код завершения 5: DirectX не найден, поврежден или установлена некорректная версия.",
  //     "6": "Код завершения 6: База данных содержит некорректные данные.",
  //     "8": "Код завершения 8: Ошибка интернет-соединения.",
  //     "9": "Код завершения 9: Session Ticket не получен. Игровой клиент не ответил на запрос получения тикета в течение 5 секунд.",
  //     "10": "Код завершения 10: Недостаточно оперативной памяти.",
  //     "11": "Код завершения 11: Ошибка сброса DirectX.",
  //     "12": "Код завершения 12: Графическая карта, которую вы используете - не подходит, устарела или неизвестна.",
  //     "13": "Код завершения 13: Игровая сессия завершена ввиду отсутствия активности.",
  //     "16": "Код завершения 16: Ваше соединение было остановлено сервером.",
  //     "33": "Код завершения 33: Ваше соединение было остановлено сервером.",
  //     "34": "Код завершения 34: Ваше соединение было остановлено сервером.",
  //     "257": "Код завершения 257: Ошибка логина. Сервер не смог распознать ваш логин.",
  //     "259": "Код завершения 259: Двойной вход. Под вашим аккаунтом уже был произведен вход с другого игрового клиента.",
  //     "265": "Код завершения 265: Клиент поврежден. Почините клиент с помощью функции восстановления.",
  //     "275": "Код завершения 275: невозможно запустить TERA.exe.",
  //     "-1": "Аварийное завершение клиента.",
  //     unknown: "Действие игрового клиента завершено. Пожалуйста, повторите снова.",
  //   },
  //   UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES: {
  //     "401": "Срок действия вашего автоматического входа истек. Пожалуйста, войдите снова.",
  //     "503": "На данный момент в игре проходят профилактические работы.",
  //     "1000": "Невозможно запустить игру. Ошибка кода Win32 {win32ErrorCode}.",
  //     "4031": "Вашему аккаунту запрещен доступ в игру до {banExpirationDate} ввиду нарушения Условий Использования. За более подробной информацией обратитесь в техническую поддержку.",
  //     "4032": "Подозрительная активность. Пожалуйста, отключите VPN и повторите снова. За более подробной информацией обратитесь в техническую поддержку.",
  //     unknown: "Ошибка SLS. Список серверов не может быть восстановлен или содержит ошибку.",
  //   },
  // },
  // ja: {
  //   UI_TEXT_UPDATE_CHECK_FOR_UPDATES: "アップデートの確認中...",
  //   UI_TEXT_UPDATE_DOWNLOADING_UPDATE: "アップデートをダウンロード中 ${percentage}%...",
  //   UI_TEXT_UPDATE_VERIFYING_INTEGRITY: "整合性を確認しています...",
  //   UI_TEXT_UPDATE_PREPARING_TO_INSTALL: "インストールの準備中...",
  //   UI_TEXT_UPDATE_INSTALLING_UPDATE: "アップデートをインストールしています...",
  //   UI_TEXT_UPDATE_FAILED: "アップデートに失敗しました: {error}",
  //   UI_TEXT_WELCOME_USERNAME: "ようこそ, {username}",
  //   UI_TEXT_LOG_OUT: "ログアウト",
  //   UI_TEXT_PATCH_PROGRESS_COMPLETED: "完了",
  //   UI_TEXT_PATCH_PROGRESS_CHECK_FOR_UPDATES: "アップデートの確認中...",
  //   UI_TEXT_PATCH_PROGRESS_RETRIEVING_INFO: "パッチ情報の取得中...",
  //   UI_TEXT_PATCH_PROGRESS_PREALLOCATING: "ストレージに事前割り当て中...",
  //   UI_TEXT_PATCH_PROGRESS_CHECKING_EXISTING: "既存のファイルをチェックしています。しばらくお待ちください。時間がかかる場合があります。",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOADING_FILES: "ファイルのダウンロード中 {percentage}%... ({downloadSize}/{totalSize} - {downloadSpeed} - 推定残り時間: {timeRemaining})",
  //   UI_TEXT_PATCH_PROGRESS_EXTRACTING_FILES: "ファイルの解凍中 {percentage}%...",
  //   UI_TEXT_PATCH_PROGRESS_CLEANING_UP: "クリーンアップ中...",
  //   UI_TEXT_PATCH_PROGRESS_DOWNLOAD_PAUSED: "ダウンロードは{percentage}%で中断しました。 ({downloadSize}/{totalSize})",
  //   UI_TEXT_PATCH_PROGRESS_FAILED: "パッチの適用に失敗し、エラーが発生しました。ランチャーを再起動して、もう一度お試しください。",
  //   UI_TEXT_PATCH_PROGRESS_REMOVE_LEGACY_INSTALL: "古いインストールデータを削除しています。しばらくお待ちください...",
  //   UI_TEXT_LAUNCH_BUTTON_LOG_IN: "ログイン",
  //   UI_TEXT_LAUNCH_BUTTON_PLAY: "プレイ",
  //   UI_TEXT_LAUNCH_BUTTON_LAUNCHING: "起動中...",
  //   UI_TEXT_LAUNCH_BUTTON_GAME_RUNNING: "ゲーム実行中",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_PAUSE: "一時停止",
  //   UI_TEXT_PATCH_PROGRESS_BUTTON_RESUME: "再開",
  //   UI_TEXT_LOGIN_MODAL_REMEMBER_ME: "ログイン状態を保持する",
  //   UI_TEXT_LOGIN_MODAL_PASSWORD_RESET: "パスワードをお忘れですか?",
  //   UI_TEXT_LOGIN_MODAL_CREATE_NEW_ACCOUNT: "新規登録",
  //   UI_TEXT_LOGIN_FAIL_INVALID_USERNAME_PASSWORD: "ユーザー名またはパスワードが無効です。",
  //   UI_TEXT_LOGIN_FAIL_ACCOUNT_NOT_ACTIVATED: "アカウントが有効化されていません。メールを確認し、アクティベーションを行ってください。",
  //   UI_TEXT_LOGIN_FAIL_UNEXPECTED_ERROR: "予期しないエラーが発生しました。再試行してください。",
  //   UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT: "サーバーに接続できません。再試行してください。",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_CHECK_FOR_UPDATES: "アップデートの確認",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_REPAIR_GAME: "ゲームの修復",
  //   UI_TEXT_CLIENT_MANAGE_DROPDOWN_OPEN_GAME_FOLDER: "ゲームフォルダを開く",
  //   UI_TEXT_LOGIN_MODAL_LOG_IN: "ログイン",
  //   UI_TEXT_NOTICE_MODAL_TITLE: "通知",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_TITLE: "クライアントの修復",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_MESSAGE: "クライアントが修復されます。このプロセスに時間がかかる場合があります。続行してもよろしいですか？",
  //   UI_TEXT_CLIENT_REPAIR_MODAL_BUTTON_START: "修復を開始する",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_OK: "閉じる",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_CANCEL: "キャンセル",
  //   UI_TEXT_TOS_MODAL_TITLE: "利用規約とプライバシーポリシー",
  //   UI_TEXT_TOS_MODAL_DESCRIPTION: "続行する前に、以下のリンクから利用規約とプライバシーポリシーをお読みください。「同意する」をクリックすることで、その内容を理解し、同意したものとみなされます。",
  //   UI_TEXT_TOS_MODAL_CLICK_BELOW_MESSAGE: "利用規約とプライバシーポリシーは下記をクリックしてご覧ください。",
  //   UI_TEXT_TOS_MODAL_TOS_CHECKBOX: "利用規約",
  //   UI_TEXT_TOS_MODAL_PRIVACY_CHECKBOX: "プライバシーポリシー",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_ACCEPT: "同意",
  //   UI_TEXT_GENERIC_MODAL_BUTTON_DECLINE: "拒否",
  //   UI_TEXT_CONNECTION_ERROR: "接続エラーです。再試行してください。",
  //   UI_TEXT_UNEXPECTED_SERVER_RESPONSE: "予期しないサーバーエラー ({errorCode})",
  //   UI_TEXT_SETTINGS_MODAL_TITLE: "設定",
  //   UI_TEXT_SETTINGS_LANG_SELECT_TITLE: "ゲーム言語",
  //   UI_TEXT_SETTINGS_LIMIT_DOWNLOAD_TITLE: "ダウンロード速度制限",
  //   UI_TEXT_SETTINGS_LAUNCHERV_TITLE: "ランチャー バージョン",
  //   UI_TEXT_SETTINGS_LAUNCHERV_VERSION: "バージョン {version}",
  //   UI_TEXT_SETTINGS_ABOUT_TITLE: "概要",
  //   UI_TEXT_SETTINGS_LAUNCHERV_UPDATE_BUTTON: "ランチャー更新",
  //   UI_TEXT_SETTINGS_LAUNCHERV_REPAIR_BUTTON: "ランチャー修復",
  //   UI_TEXT_SETTINGS_ABOUT_TOS: "利用規約",
  //   UI_TEXT_SETTINGS_ABOUT_PRIVACY: "プライバシーポリシー",
  //   UI_TEXT_SETTINGS_ABOUT_SERVICE_STATUS: "サービス稼働状況",
  //   UI_TEXT_TOOLTIP_ACCOUNT: "アカウント",
  //   UI_TEXT_TOOLTIP_PROXY: "Toolbox/Proxy",
  //   UI_TEXT_TOOLTIP_SETTINGS: "設定",
  //   UI_TEXT_TOOLTIP_MINIMIZE: "最小化",
  //   UI_TEXT_TOOLTIP_CLOSE: "閉じる",
  //   UI_TEXT_BANMODAL_TITLE: "アカウントが禁止されました",
  //   UI_TEXT_BANMODAL_MESSAGE: "利用規約および/またはゲームルールに違反したため、あなたのアカウントが禁止されました。\n\n理由: {banReason}\n終了日時: {banExpirationDate}",
  //   UI_TEXT_BANMODAL_READ_RULES: "下記のゲームルールを確認する時間を取ってください。ルールは常にディスコードサーバーで利用可能です。",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_MESSAGE: "続行するには<strong>「ゲームルールを読み理解しました」</strong>と入力してください。禁止が解除されたとしても、ルールを理解するまではプレイできません。",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_REQUIRED_MESSAGE: "ゲームルールを読み理解しました",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_COMPLETE: "ディスコードサーバーでゲームルールを確認し、禁止が解除されるのを待ってください。",
  //   UI_TEXT_BANMODAL_ACKNOWLEDGE_BUTTON: "理解しました",
  //   UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS: {
  //     "1": "ゲームルール#1の違反（許可されていないSkill Predictionモジュール）",
  //     "2": "ゲームルール#2の違反（ボット使用）",
  //     "3": "ゲームルール#3の違反（非マクロスキルの自動化）",
  //     "4": "ゲームルール#4の違反（アニメーション速度/攻撃速度の不正行為）",
  //     "5": "ゲームルール#5の違反（Skill Replacer）",
  //     "6": "ゲームルール#6の違反（無敵チート）",
  //     "7": "ゲームルール#7の違反（投射物の不正行為）",
  //     "8": "ゲームルール#8の違反（非人道的行為）",
  //     "9": "ゲームルール#9の違反（バグ、エクスプロイト、グリッチの悪用）",
  //     "10": "ゲームルール#10の違反（テレポーテーションの乱用）",
  //     "11": "ゲームルール#11の違反（ゲームプレイの人質）",
  //     "12": "ゲームルール#12の違反（キックの乱用）",
  //     "13": "ゲームルール#13の違反（クレリックの自動化）",
  //     "14": "ゲームルール#14の違反（PvPの干渉）",
  //     "101": "管理審議、サポートに連絡",
  //     "102": "複数のゲームルール違反、サポートに連絡",
  //     "0": "その他の利用規約違反、サポートに連絡",
  //   },
  //   UI_TEXT_TERMINATION_ERROR_MESSAGES: {
  //     "5": "エラーコード 5: DirectXが見つからないか、壊れているか、インストールされているバージョンが正しくありません。",
  //     "6": "エラーコード 6: データベースに無効なデータが含まれています。",
  //     "8": "エラーコード 8: ネットワークエラーが発生しました。",
  //     "9": "エラーコード 9: セッションチケットの受信に失敗しました。ランチャーはチケット要求に5秒以内に応答しませんでした。",
  //     "10": "エラーコード 10: メモリ(RAM)不足です。",
  //     "11": "エラーコード 11: DirectX のリセットに失敗しました。",
  //     "12": "エラーコード 12: グラフィックス・カードに問題があります。最小システム要件を満たしていないか、不明なエラーです。",
  //     "13": "エラーコード 13: ゲームがアクティブではないため、終了しました。",
  //     "16": "エラーコード 16: サーバーによって接続が切断されました。",
  //     "33": "エラーコード 33: サーバーによって接続が切断されました。",
  //     "34": "エラーコード 34: サーバーによって接続が切断されました。",
  //     "257": "エラーコード 257: ログインエラー。サーバーがログインを確認できませんでした。",
  //     "259": "エラーコード 259: 二重ログインエラー。別のクライアントがあなたのアカウントを使ってログインしています。",
  //     "265": "エラーコード 265: クライアントが破損しています。ランチャーの修復機能を使ってクライアントを修復してください。",
  //     "275": "エラーコード 275: 「TERA.exe」を起動できませんでした。",
  //     "-1": "クライアントが異常終了しました。",
  //     unknown: "クライアントが終了しました。再試行してください。",
  //   },
  //   UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES: {
  //     "401": "自動ログインの有効期限が切れました。再度ログインしてください。",
  //     "503": "現在メンテナンス中です",
  //     "1000": "ゲームを開始できませんでした。Win32エラーコード {win32ErrorCode}.",
  //     "4031": "あなたのアカウントは利用規約違反により、「{banExpirationDate}」までゲームへのアクセスが制限されました。詳しくはゲームサポートまでお問い合わせください。",
  //     "4032": "不審な動作が確認されました。VPNサービスを無効にし、再試行してください。詳しくはゲームサポートまでお問い合わせください。",
  //     unknown: "SLSエラー。サーバーリストを取得できないか、エラーが発生している可能性があります。",
  //   },
  // },
};

export default function LanguageString(props: any) {
  const language = React.useContext(LanguageContext);

  const { stringId, ...params }: { stringId: string; params: any } = props;
  let str = strings[language][stringId] ?? stringId;

  if (typeof str === "string") {
    for (const [key, value] of Object.entries(params)) {
      str = str.replaceAll(`{${key}}`, value);
    }

    return <span dangerouslySetInnerHTML={{ __html: str }} />;
  }

  return null;
}

export function BanMessage({ stringId, banReason, banExpirationDate }: { stringId?: string; banReason: number; banExpirationDate: number }) {
  const language = React.useContext(LanguageContext);

  let str = strings[language][stringId ?? "UI_TEXT_BANMODAL_MESSAGE"] as string;
  const dateTimeFormat = new Intl.DateTimeFormat(navigator.language, { year: "numeric", month: "long", weekday: "long", day: "numeric" });
  const banExpirationDateStr = dateTimeFormat.format(new Date(banExpirationDate));
  const banReasonStr = (strings[language]["UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS"] as LanguageSubStrings)[banReason];
  str = str
    .replaceAll("{banExpirationDate}", banExpirationDateStr)
    .replaceAll("{banReason}", banReasonStr ?? "N/A, Contact Support")
    .replaceAll("\n", "<br>");
  return <p dangerouslySetInnerHTML={{ __html: str }}></p>;
}

export function TerminationErrorMessage({ info }: { info: GameExit }) {
  const language = React.useContext(LanguageContext);

  const msgCategory = info.clientTerminated ? "UI_TEXT_TERMINATION_ERROR_MESSAGES" : "UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES";

  let str = (strings[language][msgCategory] as LanguageSubStrings)[info.code] ?? (strings[language][msgCategory] as LanguageSubStrings)["unknown"];

  for (const [key, value] of Object.entries(info)) {
    if (key === "banExpirationDate" && typeof value === "number") {
      str = str.replaceAll("{banExpirationDate}", new Date(value).toLocaleString());
    } else {
      str = str.replaceAll(`{${key}}`, value.toString());
    }
  }

  return <>{str}</>;
}

export function getString(language: LanguageType, stringId: string): string {
  return (strings[language][stringId] ?? stringId) as string;
}
