import React from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Carousel.css";

type CarouselState = {
  active: number;
  next: number;
  slideState: string;
};

type CarouselAction = {
  type: "next" | "prev" | "done";
  length: number;
};

type CarouselProps = {
  transitionTime?: number;
  interval?: number;
};

type CarouselContent = {
  img: string;
  url?: string;
};

type APICarouselContent = {
  success: boolean;
  result_code: number;
  result_message: string;
  contents: CarouselContent[];
};

const InitialCarouselState: CarouselState = {
  active: 0,
  next: 0,
  slideState: "none",
};

function CarouselReducer(state: CarouselState, action: CarouselAction) {
  switch (action.type) {
    case "next":
      return { ...state, next: (state.active + 1) % action.length, slideState: "right" };
    case "prev":
      return { ...state, next: (state.active - 1 + action.length) % action.length, slideState: "left" };
    case "done":
      return { ...state, active: state.next, slideState: "none" };
    default:
      return state;
  }
}

export default function Carousel({ transitionTime = 400, interval = 6000 }: CarouselProps) {
  const [carouselContents, setCarouselContents] = React.useState<CarouselContent[] | null>(null);
  const [state, dispatch] = React.useReducer(CarouselReducer, InitialCarouselState);

  React.useEffect(() => {
    async function fetchContents() {
      try {
        // const response = await fetch("https://launcher.mtdream.net/api/carousel_contents");
        const response = await fetch("https://launcher.dawntera.com/api/carousel_contents");
        if (!response.ok) {
          throw new Error(`Unexpected Server Response (${response.status})`);
        }

        const data: APICarouselContent = await response.json();
        setCarouselContents(data.contents);
      } catch (err) {
        console.error((err as Error).message);
      }
    }

    fetchContents();
  }, []);

  React.useEffect(() => {
    if (!carouselContents || carouselContents.length <= 1) return;

    const id = setTimeout(() => dispatch({ type: "next", length: carouselContents.length }), interval);
    return () => clearTimeout(id);
  }, [state.active, carouselContents]);

  React.useEffect(() => {
    if (!carouselContents || carouselContents.length <= 1) return;

    const id = setTimeout(() => dispatch({ type: "done", length: carouselContents.length }), transitionTime);
    return () => clearTimeout(id);
  }, [state.next, carouselContents]);

  if (!carouselContents || carouselContents.length === 0) return <div className="Carousel-Container-Placeholder"></div>;

  function next() {
    if (!carouselContents || state.slideState !== "none") return;
    dispatch({ type: "next", length: carouselContents.length });
  }

  function prev() {
    if (!carouselContents || state.slideState !== "none") return;
    dispatch({ type: "prev", length: carouselContents.length });
  }

  let contents: CarouselContent[] = [];
  let animation: string | undefined = undefined;

  if (state.slideState !== "none") {
    if (state.slideState === "right") {
      animation = `slide-in-right ${transitionTime}ms ease forwards`;
      contents = [carouselContents[state.active], carouselContents[state.next]];
    } else if (state.slideState === "left") {
      animation = `slide-in-left ${transitionTime}ms ease forwards`;
      contents = [carouselContents[state.next], carouselContents[state.active]];
    }
  } else {
    contents = [carouselContents[state.active]];
  }

  return (
    <div className="Carousel-Container">
      <ul className="Carousel-Wrapper" style={animation ? { animation } : undefined}>
        {contents.map((entry: CarouselContent, i) => (
          <li key={i} className="Carousel-Content" style={{ cursor: entry.url ? "pointer" : "default" }} onClick={() => entry.url && window.ipcRenderer.send("openExternal", entry.url)}>
            <img src={entry.img} alt="" className="Carousel-Entry" />
          </li>
        ))}
      </ul>
      {carouselContents.length > 1 && (
        <>
          <div className="Carousel-Button Right" onClick={next}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
          <div className="Carousel-Button Left" onClick={prev}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
        </>
      )}
    </div>
  );
}
