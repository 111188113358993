import React from "react";
import "./ErrorInfo.css";
import LanguageString from "./LanguageString";

type ErrorInfoProps = {
  errorMsg: string | ErrorObject;
  onClick?: () => void;
};

export default function ErrorInfo({ errorMsg, onClick }: ErrorInfoProps) {
  let messageId: string, errorCode: string | number | undefined;

  if (typeof errorMsg === "object") {
    ({ messageId, errorCode } = errorMsg);
  } else {
    messageId = errorMsg;
  }

  return (
    <div className="Error-Info-Wrapper" onClick={onClick}>
      <p className="Error-Info-Message">
        <LanguageString stringId={messageId} errorCode={errorCode} />
      </p>
    </div>
  );
}
