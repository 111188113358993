import React from "react";
import ReactDOM from "react-dom/client";
import { config as faConfig } from "@fortawesome/fontawesome-svg-core";
faConfig.autoAddCss = false;

import "./index.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import App from "./App";
import Update from "./Update";

// TODO: Improve this for the update window
if (window.launcherVersion) {
  console.log(`Launcher version: ${window.launcherVersion}`);
  document.getElementById("loadingContainer")?.remove();

  const root = document.getElementById("root");
  if (root) {
    ReactDOM.createRoot(root).render(<React.StrictMode>{window.location.search === "?update" || window.updatePending ? <Update /> : <App />}</React.StrictMode>);
  }
}
