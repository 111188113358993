import React from "react";
import "./LoadingIcon.css";

export default function LoadingIcon({ dim = false }: { dim?: boolean }) {
  return (
    <div className="Loading-Icon-Wrapper" style={dim ? { backgroundColor: "#00000080" } : {}}>
      <div className="Loading-Icon"></div>
    </div>
  );
}
