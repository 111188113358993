import React from "react";
import "./NewsContainer.css";

type NewsContent = {
  title: string;
  url: string;
  date: string;
};

export default function NewsContainer() {
  const [newsContents, setNewsContents] = React.useState<NewsContent[]>([]);

  React.useEffect(() => {
    async function fetchContents() {
      try {
        // const response = await fetch("https://launcher.mtdream.net/api/news");
        const response = await fetch("https://launcher.dawntera.com/api/news");
        const json = await response.json();
        setNewsContents(json.contents);
      } catch (err) {
        console.error((err as Error).message);
      }
    }

    fetchContents();
  }, []);

  if (newsContents.length === 0) return null;

  return (
    <div className="News-Container">
      <ul className="News-List">
        {newsContents.map((content, index) => (
          <NewsItem key={index} title={content.title} url={content.url} date={new Date(content.date).toLocaleDateString()} />
        ))}
      </ul>
    </div>
  );
}

function NewsItem({ title, url, date }: NewsContent) {
  function onClick() {
    window.ipcRenderer.send("openExternal", url);
  }

  return (
    <li className="News-Item" onClick={onClick}>
      <span className="News-Item-Title">{title}</span>
      <span className="News-Item-Date">{date}</span>
    </li>
  );
}
