import React from "react";
import "./GameRules.css";
import { LanguageContext } from "../Contexts";

export default function GameRules() {
  const language = React.useContext(LanguageContext);

  const ko = (
    <ol start={0} className="Game-Rules-Container">
      <li>
        The only officially supported game mods are the ones that are already pre-installed in our built-in &quot;Toolbox&quot;, as well as mods listed in the &quot;Get More Mods&quot; tab in toolbox.
        <ul>
          <li>Any other mod is considered unsupported. Cheats, scripts, hacks and/or software that provide an advantage over your co-players are strictly prohibited. MT: The Dream team reserves every right to restrict your account from accessing the game indefinitely, if they determine usage of any kind of unsupported mods.</li>
        </ul>
      </li>
      <li>
        We only officially support the use of our NGSP skill prediction (available in our toolbox). Any other skill prediction modules are unsupported, and you are liable for ensuring that it follows our rules.
        <ul>
          <li>If we determine that fair play is disrupted, we reserve the right to take action against usage of other, unsupported skill predictions.</li>
        </ul>
      </li>
      <li>
        Any software/mod that attempts to fully automate (&quot;bot&quot;) some (or all) parts of our game is not allowed.
        <ul>
          <li>Example: Auto Fish</li>
          <li>Note: Usage of &quot;AutoHotkey&quot; and &quot;Macro Maker&quot; is allowed unless a macro break one of our other rules.</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that automate skill casts or any part of the lock-on mechanic are not allowed.
        <ul>
          <li>Examples: Auto Arise, Auto Heal, Auto Target</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that speed up skill animations or reduce animation locks beyond normal limits are not allowed.
        <ul>
          <li>Examples: Any &quot;fast&quot; mod, Instant Retaliate, Instant Revive</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that ignore a skill&apos;s chain or cast requirements are not allowed.
        <ul>
          <li>Example: Skill Replacer</li>
        </ul>
      </li>
      <li>
        Any software/mod that make you immune to damage and/or crowd control effects are not allowed.
        <ul>
          <li>Examples: Anti CC, any &quot;invincibility&quot; mod</li>
        </ul>
      </li>
      <li>Any software/mod that automatically hit your projectiles for you are not allowed.</li>
      <li>
        Any software/mod or macro(s) that perform otherwise impossible actions are not allowed.
        <ul>
          <li>Example: Barrage Skip</li>
        </ul>
      </li>
      <li>Do not abuse bugs, exploits or glitches, including those from Skill Prediction.</li>
      <li>Do not abuse teleport software/mod.</li>
      <li>
        Do not hold people&apos;s gameplay hostage by not participating.
        <ul>
          <li>If you willingly enter content such as a dungeon or battleground, you must participate or leave the group via /drop.</li>
        </ul>
      </li>
      <li>
        Do not abuse the kick feature.
        <ul>
          <li>Kicks are only to be used if someone is AFK or intentionally trolling. Do not kick for any other reason, even if someone is &quot;bad&quot; or flaming.</li>
        </ul>
      </li>
    </ol>
  );

  const en = (
    <ol start={0} className="Game-Rules-Container">
      <li>
        The only officially supported game mods are the ones that are already pre-installed in our built-in &quot;Toolbox&quot;, as well as mods listed in the &quot;Get More Mods&quot; tab in toolbox.
        <ul>
          <li>Any other mod is considered unsupported. Cheats, scripts, hacks and/or software that provide an advantage over your co-players are strictly prohibited. MT: The Dream team reserves every right to restrict your account from accessing the game indefinitely, if they determine usage of any kind of unsupported mods.</li>
        </ul>
      </li>
      <li>
        We only officially support the use of our NGSP skill prediction (available in our toolbox). Any other skill prediction modules are unsupported, and you are liable for ensuring that it follows our rules.
        <ul>
          <li>If we determine that fair play is disrupted, we reserve the right to take action against usage of other, unsupported skill predictions.</li>
        </ul>
      </li>
      <li>
        Any software/mod that attempts to fully automate (&quot;bot&quot;) some (or all) parts of our game is not allowed.
        <ul>
          <li>Example: Auto Fish</li>
          <li>Note: Usage of &quot;AutoHotkey&quot; and &quot;Macro Maker&quot; is allowed unless a macro break one of our other rules.</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that automate skill casts or any part of the lock-on mechanic are not allowed.
        <ul>
          <li>Examples: Auto Arise, Auto Heal, Auto Target</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that speed up skill animations or reduce animation locks beyond normal limits are not allowed.
        <ul>
          <li>Examples: Any &quot;fast&quot; mod, Instant Retaliate, Instant Revive</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that ignore a skill&apos;s chain or cast requirements are not allowed.
        <ul>
          <li>Example: Skill Replacer</li>
        </ul>
      </li>
      <li>
        Any software/mod that make you immune to damage and/or crowd control effects are not allowed.
        <ul>
          <li>Examples: Anti CC, any &quot;invincibility&quot; mod</li>
        </ul>
      </li>
      <li>Any software/mod that automatically hit your projectiles for you are not allowed.</li>
      <li>
        Any software/mod or macro(s) that perform otherwise impossible actions are not allowed.
        <ul>
          <li>Example: Barrage Skip</li>
        </ul>
      </li>
      <li>Do not abuse bugs, exploits or glitches, including those from Skill Prediction.</li>
      <li>Do not abuse teleport software/mod.</li>
      <li>
        Do not hold people&apos;s gameplay hostage by not participating.
        <ul>
          <li>If you willingly enter content such as a dungeon or battleground, you must participate or leave the group via /drop.</li>
        </ul>
      </li>
      <li>
        Do not abuse the kick feature.
        <ul>
          <li>Kicks are only to be used if someone is AFK or intentionally trolling. Do not kick for any other reason, even if someone is &quot;bad&quot; or flaming.</li>
        </ul>
      </li>
    </ol>
  );

  const de = (
    <ol start={0} className="Game-Rules-Container">
      <li>
        Die einzigen offiziell unterstützten Spielmodifikationen sind die, die bereits in unserer integrierten &quot;Toolbox&quot; vorinstalliert sind, sowie Mods, die im Tab &quot;Get More Mods&quot; in der Toolbox aufgeführt sind.
        <ul>
          <li>Jede andere Modifikation wird als nicht unterstützt betrachtet. Cheats, Skripte, Hacks und/oder Software, die einen Vorteil gegenüber Ihren Mitspielern verschaffen, sind strengstens verboten. MT: The Dream Team behält sich das Recht vor, Ihren Account dauerhaft vom Spiel auszuschließen, wenn die Nutzung von nicht unterstützten Modifikationen festgestellt wird.</li>
        </ul>
      </li>
      <li>
        Wir unterstützen offiziell nur die Verwendung unserer NGSP-Skillvorhersage (verfügbar in unserer Toolbox). Andere Skillvorhersage-Module werden nicht unterstützt, und Sie sind dafür verantwortlich, dass sie unseren Regeln entsprechen.
        <ul>
          <li>Wenn wir feststellen, dass das faire Spiel gestört wird, behalten wir uns das Recht vor, Maßnahmen gegen die Nutzung anderer, nicht unterstützter Skillvorhersagen zu ergreifen.</li>
        </ul>
      </li>
      <li>
        Jegliche Software/Modifikation, die versucht, Teile (oder das ganze) unseres Spiels vollständig zu automatisieren (&quot;botten&quot;), ist nicht erlaubt.
        <ul>
          <li>Beispiel: Auto Fish</li>
          <li>Hinweis: Die Verwendung von &quot;AutoHotkey&quot; und &quot;Macro Maker&quot; ist erlaubt, solange ein Makro nicht gegen eine unserer anderen Regeln verstößt.</li>
        </ul>
      </li>
      <li>
        Jegliche Software/Modifikation oder Makro(s), die Skillcasts oder einen Teil der Lock-On-Mechanik automatisieren, sind nicht erlaubt.
        <ul>
          <li>Beispiele: Auto Arise, Auto Heal, Auto Target</li>
        </ul>
      </li>
      <li>
        Jegliche Software/Modifikation oder Makro(s), die Skillanimationen beschleunigen oder Animationssperren über die normalen Grenzen hinaus reduzieren, sind nicht erlaubt.
        <ul>
          <li>Beispiele: Jede &quot;schnelle&quot; Mod, Instant Retaliate, Instant Revive</li>
        </ul>
      </li>
      <li>
        Jegliche Software/Modifikation oder Makro(s), die die Ketten- oder Zauberanforderungen eines Skills ignorieren, sind nicht erlaubt.
        <ul>
          <li>Beispiel: Skill Replacer</li>
        </ul>
      </li>
      <li>
        Jegliche Software/Modifikation, die Sie immun gegen Schaden und/oder Crowd-Control-Effekte macht, ist nicht erlaubt.
        <ul>
          <li>Beispiele: Anti CC, jede &quot;Unbesiegbarkeits&quot;-Mod</li>
        </ul>
      </li>
      <li>Jegliche Software/Modifikation, die Ihre Projektile automatisch trifft, ist nicht erlaubt.</li>
      <li>
        Jegliche Software/Modifikation oder Makro(s), die sonst unmögliche Aktionen ausführen, sind nicht erlaubt.
        <ul>
          <li>Beispiel: Barrage Skip</li>
        </ul>
      </li>
      <li>Missbrauchen Sie keine Bugs, Exploits oder Glitches, einschließlich derer aus der Skillvorhersage.</li>
      <li>Missbrauchen Sie keine Teleport-Software/Modifikation.</li>
      <li>
        Halten Sie das Spiel anderer Leute nicht durch Nichtteilnahme als Geisel.
        <ul>
          <li>Wenn Sie bereitwillig an Inhalten wie einem Dungeon oder Schlachtfeld teilnehmen, müssen Sie teilnehmen oder die Gruppe über /drop verlassen.</li>
        </ul>
      </li>
      <li>
        Missbrauchen Sie nicht die Kick-Funktion.
        <ul>
          <li>Kickings dürfen nur verwendet werden, wenn jemand AFK ist oder absichtlich trollt. Kicken Sie nicht aus anderen Gründen, auch nicht, wenn jemand &quot;schlecht&quot; ist oder flamt.</li>
        </ul>
      </li>
    </ol>
  );

  const fr = (
    <ol start={0} className="Game-Rules-Container">
      <li>
        Les seuls mods de jeu officiellement supportés sont ceux qui sont déjà préinstallés dans notre &quot;Toolbox&quot; intégrée, ainsi que les mods listés dans l&apos;onglet &quot;Get More Mods&quot; dans la toolbox.
        <ul>
          <li>Toute autre modification est considérée comme non supportée. Les triches, scripts, hacks et/ou logiciels qui fournissent un avantage sur vos coéquipiers sont strictement interdits. MT: The Dream Team se réserve le droit de restreindre définitivement l&apos;accès à votre compte s&apos;ils déterminent l&apos;utilisation de mods non supportés.</li>
        </ul>
      </li>
      <li>
        Nous supportons officiellement uniquement l&apos;utilisation de notre prédiction de compétences NGSP (disponible dans notre toolbox). Tout autre module de prédiction de compétences n&apos;est pas supporté, et vous êtes responsable de vous assurer qu&apos;il respecte nos règles.
        <ul>
          <li>Si nous déterminons que le fair-play est perturbé, nous nous réservons le droit de prendre des mesures contre l&apos;utilisation d&apos;autres prédictions de compétences non supportées.</li>
        </ul>
      </li>
      <li>
        Tout logiciel/modification qui tente d&apos;automatiser entièrement (&quot;boter&quot;) certaines (ou toutes) parties de notre jeu n&apos;est pas autorisé.
        <ul>
          <li>Exemple: Auto Fish</li>
          <li>Note: L&apos;utilisation de &quot;AutoHotkey&quot; et &quot;Macro Maker&quot; est autorisée à moins qu&apos;un macro ne viole une de nos autres règles.</li>
        </ul>
      </li>
      <li>
        Tout logiciel/modification ou macro(s) qui automatisent les lancements de compétences ou une partie de la mécanique de verrouillage ne sont pas autorisés.
        <ul>
          <li>Exemples: Auto Arise, Auto Heal, Auto Target</li>
        </ul>
      </li>
      <li>
        Tout logiciel/modification ou macro(s) qui accélèrent les animations des compétences ou réduisent les verrous d&apos;animation au-delà des limites normales ne sont pas autorisés.
        <ul>
          <li>Exemples: Tout mod &quot;rapide&quot;, Instant Retaliate, Instant Revive</li>
        </ul>
      </li>
      <li>
        Tout logiciel/modification ou macro(s) qui ignorent les exigences de chaîne ou de lancement d&apos;une compétence ne sont pas autorisés.
        <ul>
          <li>Exemple: Skill Replacer</li>
        </ul>
      </li>
      <li>
        Tout logiciel/modification qui vous rend immunisé contre les dégâts et/ou les effets de contrôle de foule ne sont pas autorisés.
        <ul>
          <li>Exemples: Anti CC, tout mod &quot;invincibilité&quot;</li>
        </ul>
      </li>
      <li>Tout logiciel/modification qui touche automatiquement vos projectiles ne sont pas autorisés.</li>
      <li>
        Tout logiciel/modification ou macro(s) qui exécutent des actions autrement impossibles ne sont pas autorisés.
        <ul>
          <li>Exemple: Barrage Skip</li>
        </ul>
      </li>
      <li>N&apos;abusez pas des bugs, exploits ou glitches, y compris ceux de la Prédiction de Compétences.</li>
      <li>N&apos;abusez pas des logiciels/mods de téléportation.</li>
      <li>
        Ne prenez pas en otage le gameplay des autres en ne participant pas.
        <ul>
          <li>Si vous entrez volontairement dans des contenus tels qu&apos;un donjon ou un champ de bataille, vous devez participer ou quitter le groupe via /drop.</li>
        </ul>
      </li>
      <li>
        N&apos;abusez pas de la fonctionnalité de kick.
        <ul>
          <li>Les kicks ne doivent être utilisés que si quelqu&apos;un est AFK ou trolle intentionnellement. Ne kickez pour aucune autre raison, même si quelqu&apos;un est &quot;mauvais&quot; ou flamme.</li>
        </ul>
      </li>
    </ol>
  );

  const ru = (
    <ol start={0} className="Game-Rules-Container">
      <li>
        Единственные официально поддерживаемые моды игры - это те, которые уже предустановлены в нашей встроенной &quot;Toolbox&quot;, а также моды, перечисленные во вкладке &quot;Get More Mods&quot; в Toolbox.
        <ul>
          <li>Любой другой мод считается неподдерживаемым. Читы, скрипты, хаки и/или программное обеспечение, предоставляющее преимущество перед другими игроками, строго запрещены. MT: The Dream Team оставляет за собой право навсегда заблокировать ваш аккаунт, если будет обнаружено использование любых неподдерживаемых модов.</li>
        </ul>
      </li>
      <li>
        Мы официально поддерживаем только использование нашего прогнозирования навыков NGSP (доступно в нашей Toolbox). Любые другие модули прогнозирования навыков не поддерживаются, и вы несете ответственность за соблюдение ими наших правил.
        <ul>
          <li>Если мы определим, что честная игра нарушена, мы оставляем за собой право принять меры против использования других неподдерживаемых прогнозов навыков.</li>
        </ul>
      </li>
      <li>
        Любое программное обеспечение/мод, пытающееся полностью автоматизировать (&quot;ботить&quot;) некоторые (или все) части нашей игры, не допускается.
        <ul>
          <li>Пример: Auto Fish</li>
          <li>Примечание: Использование &quot;AutoHotkey&quot; и &quot;Macro Maker&quot; разрешено, если макрос не нарушает одно из наших других правил.</li>
        </ul>
      </li>
      <li>
        Любое программное обеспечение/мод или макро(ы), автоматизирующие использование навыков или любую часть механики прицеливания, не допускаются.
        <ul>
          <li>Примеры: Auto Arise, Auto Heal, Auto Target</li>
        </ul>
      </li>
      <li>
        Любое программное обеспечение/мод или макро(ы), ускоряющие анимацию навыков или уменьшающие задержку анимации за пределами нормальных ограничений, не допускаются.
        <ul>
          <li>Примеры: Любой &quot;быстрый&quot; мод, Instant Retaliate, Instant Revive</li>
        </ul>
      </li>
      <li>
        Любое программное обеспечение/мод или макро(ы), игнорирующие цепочку или требования каста навыка, не допускаются.
        <ul>
          <li>Пример: Skill Replacer</li>
        </ul>
      </li>
      <li>
        Любое программное обеспечение/мод, делающие вас неуязвимым к урону и/или эффектам контроля, не допускаются.
        <ul>
          <li>Примеры: Anti CC, любой мод &quot;неуязвимости&quot;</li>
        </ul>
      </li>
      <li>Любое программное обеспечение/мод, автоматически попадающее вашими снарядами, не допускается.</li>
      <li>
        Любое программное обеспечение/мод или макро(ы), выполняющее иные невозможные действия, не допускается.
        <ul>
          <li>Пример: Barrage Skip</li>
        </ul>
      </li>
      <li>Не злоупотребляйте багами, эксплойтами или глитчами, включая те, которые связаны с прогнозированием навыков.</li>
      <li>Не злоупотребляйте телепорт-программами/модами.</li>
      <li>
        Не удерживайте игровой процесс других людей в заложниках, не участвуя.
        <ul>
          <li>Если вы добровольно вступаете в такие активности, как подземелья или поля сражений, вы должны участвовать или покинуть группу через /drop.</li>
        </ul>
      </li>
      <li>
        Не злоупотребляйте функцией кика.
        <ul>
          <li>Кики должны использоваться только если кто-то AFK или намеренно троллит. Не используйте кик по другим причинам, даже если кто-то &quot;плохой&quot; или флеймит.</li>
        </ul>
      </li>
    </ol>
  );

  const ja = (
    <ol start={0} className="Game-Rules-Container">
      <li>
        公式にサポートされているゲームの改造は、組み込みの&quot;Toolbox&quot;にあらかじめインストールされているもの、またはToolboxの&quot;Get More Mods&quot;タブにリストされているものだけです。
        <ul>
          <li>その他の改造はサポートされていません。チート、スクリプト、ハック、および/または共プレイヤーに対して有利になるソフトウェアは厳禁です。MT: The Dream Teamは、サポートされていない改造の使用を確認した場合、アカウントのゲームアクセスを無期限に制限する権利を有します。</li>
        </ul>
      </li>
      <li>
        私たちは、Toolboxで利用可能なNGSPスキル予測の使用のみを公式にサポートしています。その他のスキル予測モジュールはサポートされておらず、あなたはそれが私たちの規則に従うことを保証する責任があります。
        <ul>
          <li>公正なプレイが妨げられていると判断した場合、他のサポートされていないスキル予測の使用に対して措置を取る権利を有します。</li>
        </ul>
      </li>
      <li>
        ゲームの一部（または全部）を完全に自動化する（「ボット化」する）ソフトウェア/改造は許可されていません。
        <ul>
          <li>例: Auto Fish</li>
          <li>注: 「AutoHotkey」および「Macro Maker」の使用は、マクロが他の規則のいずれかに違反しない限り許可されます。</li>
        </ul>
      </li>
      <li>
        スキルキャストやロックオンメカニズムの一部を自動化するソフトウェア/改造やマクロは許可されていません。
        <ul>
          <li>例: Auto Arise, Auto Heal, Auto Target</li>
        </ul>
      </li>
      <li>
        スキルアニメーションを通常の限界を超えて速くしたり、アニメーションロックを短縮するソフトウェア/改造やマクロは許可されていません。
        <ul>
          <li>例: いかなる「高速」モッド, Instant Retaliate, Instant Revive</li>
        </ul>
      </li>
      <li>
        スキルのチェーンやキャスト要件を無視するソフトウェア/改造やマクロは許可されていません。
        <ul>
          <li>例: Skill Replacer</li>
        </ul>
      </li>
      <li>
        ダメージやクラウドコントロール効果に対して無敵にするソフトウェア/改造は許可されていません。
        <ul>
          <li>例: Anti CC, いかなる「無敵」モッド</li>
        </ul>
      </li>
      <li>あなたの発射物を自動的に命中させるソフトウェア/改造は許可されていません。</li>
      <li>
        その他不可能なアクションを実行するソフトウェア/改造やマクロは許可されていません。
        <ul>
          <li>例: Barrage Skip</li>
        </ul>
      </li>
      <li>バグ、エクスプロイト、グリッチの悪用をしないでください。スキル予測に関連するものも含みます。</li>
      <li>テレポートソフトウェア/改造の悪用をしないでください。</li>
      <li>
        参加しないことで他の人のゲームプレイを人質にしないでください。
        <ul>
          <li>ダンジョンやバトルグラウンドなどのコンテンツに自発的に参加する場合は、参加するか、/dropを使用してグループを離れてください。</li>
        </ul>
      </li>
      <li>
        キック機能の悪用をしないでください。
        <ul>
          <li>キックは、誰かがAFKであるか、意図的にトローリングしている場合にのみ使用されます。それ以外の理由でキックしないでください。たとえ誰かが「悪い」またはフレーミングしている場合でも。</li>
        </ul>
      </li>
    </ol>
  );

  switch (language) {
    // case "en":
    //   return en;
    // case "de":
    //   return de;
    // case "fr":
    //   return fr;
    // case "ru":
    //   return ru;
    // case "ja":
    //   return ja;
    // default:
    case "ko":
      return ko;
    default:
      return ko;
  }
}
